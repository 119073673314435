import { Button, Paper } from "@mui/material";
import React from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import { isMobile } from "../common/utils";
import { dismissContactUs } from "../reducer/HomeReducer";
import { CONSULATION } from "../RouterPath";
import "./ContactUs.scss"

class ContactUs extends React.Component {
    timer: any
    onClickShop = () => {
        this.dismiss()
    }

    onClickConsultation = () => {
        this.dismiss()
        window.location.href = CONSULATION
    }

    dismiss() {
        const { dismissContactUs } = this.props as any
        dismissContactUs()
    }

    componentDidMount(): void {
        this.timer = setTimeout(() => {
            this.timer = undefined
            this.dismiss()
        }, 10000)

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    componentWillUnmount(): void {
        if (this.timer) {
            clearTimeout(this.timer)
        }
    }

    render(): React.ReactNode {
        return <Translation>{t =>
            <Paper elevation={3} className={isMobile() ? "ContactUsContainerMobile" : "ContactUsContainer"}>
                <div className="ContactUsDesc">{t("contactUsDes")}</div>
                <div className="ContactButtonContainer">
                    <Button variant="contained" className="ContactButton" onClick={this.onClickShop}>{t("shopMenu")}</Button>
                    <Button variant="contained" className="ContactButton" onClick={this.onClickConsultation}>{t("consultation")}</Button>
                </div>
            </Paper>
        }
        </Translation>
    }
}


const mapDispatchToProps = (dispatch: any) => ({
    dismissContactUs: () => {
        dispatch(dismissContactUs())
    }
})

export default connect(null, mapDispatchToProps)(ContactUs)