
export const OK = 0
export const INTERNAL_ERROR = 1

export const UPLOAD_NO_FILE = 101

export const TRANSFORM_ERROR = 200
export const TRANSFORM_TASK_NOT_EXIST = 201
export const TRANSFORM_NOT_COMPLETE = 202
export const TRANSFORM_TASKID_EMPTY = 203
export const TRANSFORM_TASK_ERROR = 204