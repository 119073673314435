import React from "react";
import { connect } from "react-redux";
import BaseProps from "../common/BaseProps";
import { isMobile } from "../common/utils";
import { cameraClosed, cameraHasReady, finishCapture } from "../reducer/CameraReducer";
import { imageCaptured } from "../reducer/CaptureReducer";
import "./CameraView.css"

interface State {

}

interface Props extends BaseProps {
    onCaptureResult?: (result: any) => {}
}

class CameraView extends React.PureComponent<Props, State> {

    video_ref: any
    unsupport_ref: any
    timer: any
    isUnmounted: boolean
    constructor(prop: Props) {
        super(prop)
        this.video_ref = React.createRef()
        this.unsupport_ref = React.createRef()
        this.isUnmounted = false
        this.state = {}
    }

    componentDidMount(): void {
        this.isUnmounted = false
        this.timer = setTimeout(() => { this.openCamera() }, 1000)
    }

    componentWillUnmount(): void {
        this.isUnmounted = true
        if (this.timer) {
            clearTimeout(this.timer)
            this.timer = undefined;
        }
        this.closeCamera();
    }

    openCamera = async () => {
        let videoEle = this.video_ref.current;
        let unsupportEle = this.unsupport_ref.current;
        let constraints: MediaStreamConstraints = {
            video: { width: videoEle.clientWidth, height: videoEle.clientHeight },
            audio: false,
        };
        try {
            if (navigator.mediaDevices === undefined) {
                (navigator as any).mediaDevices = {};
            }
            if (navigator.mediaDevices.getUserMedia === undefined) {
                navigator.mediaDevices.getUserMedia = function (constraints) {

                    // 首先，如果有getUserMedia的话，就获得它
                    var getUserMedia = (navigator as any).webkitGetUserMedia || (navigator as any).mozGetUserMedia;

                    // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
                    if (!getUserMedia) {
                        if (unsupportEle == null) {
                            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                        } else {
                            return new Promise(function (resolve, reject) {
                                unsupportEle.click();
                            });
                        }
                    }

                    // 否则，为老的navigator.getUserMedia方法包裹一个Promise
                    return new Promise(function (resolve, reject) {
                        getUserMedia.call(navigator, constraints, resolve, reject);
                    });
                }
            }
            let MediaStream = await navigator.mediaDevices.getUserMedia(constraints);
            videoEle.srcObject = MediaStream;
            videoEle.play();
            let { cameraHasReady } = this.props as any
            cameraHasReady();
            if (this.isUnmounted) {
                this.closeCamera()
            }
        } catch (e) {
            alert(e);
        }
    }

    closeCamera = () => {
        let video = this.video_ref.current;
        const stream = video.srcObject;
        if (!stream) {
            return
        }
        if ('getTracks' in stream) {
            const tracks = stream.getTracks();
            tracks.forEach((track: any) => {
                track.stop();
            });
        }
        let { cameraClosed } = this.props as any
        cameraClosed()
    }

    captureImage = () => {
        let video = this.video_ref.current;
        let canvas: any = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
        let img = canvas.toDataURL("image/png");
        const { finishCapture, imageCaptured } = this.props as any
        imageCaptured && imageCaptured(img)
        finishCapture && finishCapture()
    };



    render(): React.ReactNode {
        const { doCapture } = this.props as any
        if (doCapture) {
            this.captureImage()
        }
        return (<div style={{ display: "flex", flexDirection: "row" }}>
            <video
                ref={this.video_ref}
                autoPlay
                playsInline
                className={["Video", this.props.className].join(" ")}
            ></video>
            {isMobile() && <input type="file" ref={this.unsupport_ref} accept="image/*" capture="environment" style={{ display: 'none' }} />}
        </div>)
    }
}

const mapStateToProps = (state: any) => ({
    doCapture: state.camera.doCapture,
});
const mapDispatchToProps = (dispatch: any) => ({

    finishCapture: () => {
        dispatch(finishCapture())
    },
    imageCaptured: (imgData: any) => {
        dispatch(imageCaptured(imgData))
    },
    cameraHasReady: () => {
        dispatch(cameraHasReady())
    },
    cameraClosed: () => {
        dispatch(cameraClosed())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(CameraView);