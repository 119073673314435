import React from "react";
import BaseProps from "../common/BaseProps";
import "./Home.scss";
import avatar from "../images/avatar.png";
import { Button } from "@mui/material";
import HairStyleSelector from "../components/HairStyleSelector";
import { Wig } from "../data/model/Wig";
import PreReadyModal from "../components/PreReadyModal";
import CaptureModal from "../components/CaptureModal";
import { connect } from "react-redux";
import BasePage, {
  BasePageState,
  mapDispatchToProps,
  mapStateToProps,
} from "../common/BasePage";
import ThreeModel from "../components/ThreeModel";
import { CheckResult } from "../data/model/CheckResult";
import { IS_DEMO } from "../config";

interface IState extends BasePageState {
  showReady: boolean;
  showCapture: boolean;
  show3DModel: boolean;
  transformResult: CheckResult | undefined;
  wig: Wig | undefined;
}
class Home extends BasePage<BaseProps, IState> {
  constructor(props: BaseProps) {
    super(props);
    this.state = {
      showReady: false,
      showCapture: false,
      show3DModel: false,
      transformResult: undefined,
      wig: undefined,
      snackBarMsg: null,
      showSnackBar: false,
    };
  }

  onClickStart = () => {
    this.setState({
      showReady: true,
      showCapture: false,
    });
  };

  onClickReadyStart = () => {
    this.setState({
      showReady: false,
      showCapture: true,
    });
  };

  onFinishTransform = (
    transformResult: CheckResult | undefined,
    isSuccess: boolean,
    isCancel: boolean
  ) => {
    this.setState({
      showCapture: false,
      showReady: false,
    });
    if (isCancel || !isSuccess) {
      return;
    }
    if (!transformResult || transformResult.modelPath === "") {
      return;
    }
    this.setState({
      show3DModel: true,
      transformResult: transformResult,
    });
  };

  // h1 = "1b93d67affcf11ed9f3c6657ec214ba2" // 1
  // h2 = "78d3e87cffc311ed98746657ec214ba2" // 0
  // h3 = "481c32feffc611edaea76657ec214ba2" // -1
  // hd = this.h3
  // demoWig = new Wig("", "http://127.0.0.1:8000/uploads/wig/models/hair1.glb");
  // demoResult = new CheckResult(
  //   `http://127.0.0.1:8000/uploads/ai/${this.hd}/result/${this.hd}.glb`,
  //   [-1.004468408466567375, 0.005177043301477234, 0.002735558695508912]
  // );
  renderContent(t: any): React.ReactNode {
    return (
      <div className="PageContent">
        <div className="HomeTitle">{t("homeTitle")}</div>
        {this.state.transformResult &&
        this.state.transformResult.modelPath &&
        this.state.transformResult.modelPath.length > 0 ? (
          <ThreeModel
            transformResult={
              /*IS_DEMO ? this.demoResult : */this.state.transformResult
            }
            wig={/*IS_DEMO ? this.demoWig : */this.state.wig}
            className="AvatarFrame"
          />
        ) : (
          <div className="AvatarFrame">
            <div className="AvatarCircle">
              <img src={avatar} className="Avatar" alt="avatar" />
            </div>
          </div>
        )}
        <div className="HomeTips">{t("homeTips")}</div>
        <Button
          variant="contained"
          className="Button"
          onClick={this.onClickStart}
        >
          {t("startTry")}
        </Button>
        <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <HairStyleSelector onSelectStyle={this.onSelectHairStyle} />
        </div>
        {this.state.showReady && (
          <PreReadyModal
            onClickStart={this.onClickReadyStart}
            open={this.state.showReady}
            onCancel={() => {
              this.setState({
                showCapture: false,
                showReady: false,
              });
            }}
          />
        )}
        {this.state.showCapture && (
          <CaptureModal
            open={this.state.showCapture}
            onFinish={this.onFinishTransform}
          />
        )}
      </div>
    );
  }

  onSelectHairStyle = (wig: Wig) => {
    if (wig) {
      this.setState({
        wig: wig,
      });
    }
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);
