export class Goods {
  img: string | undefined;
  name: string | undefined;
  description: string | undefined;
  price: number = 0;
  constructor(
    img: string | undefined = undefined,
    name: string | undefined = undefined,
    description: string | undefined = undefined,
    price: number = 0
  ) {
    this.img = img;
    this.name = name;
    this.price = price;
    this.description = description;
  }
}
