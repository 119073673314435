import { Button, List, TextField } from "@mui/material";
import { connect } from "react-redux";
import BasePage, {
  BasePageState,
  mapDispatchToProps,
  mapStateToProps,
  PageStyle,
} from "../common/BasePage";
import MessageItemFromMe from "../components/message/MessageItemFromMe";
import MessageItemFromOther from "../components/message/MessageItemFromOther";
import Connection from "../data/customer_service/Connection";
import Message from "../data/customer_service/message/Message";
import RecordRequest, {
  SessionInfo,
} from "../data/customer_service/request/RecordRequest";
import "./Consultation.scss";
import "@mui/material/ListItem";
import emoji from "../images/emoji.png";
import image from "../images/image.png";
import "../data/customer_service/message/Message";
import BaseProps from "../common/BaseProps";
import React from "react";
import SendMesssgeRequest from "../data/customer_service/request/SendMessageRequest";
import ImageMessage from "../data/customer_service/message/ImageMessage";
import TextMessage from "../data/customer_service/message/TextMessage";
import { isMobile } from "../common/utils";
import EmojiPanel from "../components/EmojiPanel";
import ApiError from "../data/error/ApiError";
import "../components/message/SystemMessageItem";
import SystemMessage from "../data/customer_service/message/SystemMessage";
import SystemMessageItem from "../components/message/SystemMessageItem";
import ImagePreview from "../components/ImagePreview";

interface State extends BasePageState {
  msgs: Message[];
  showEmoji: boolean;
  messageInput: string;
  showImage: string | undefined;
}

class Consultation extends BasePage<BaseProps, State> {
  ref: any = null;
  listView: any = null;
  inputRef: any = null;
  wsConn: Connection | null = null;
  recordInfo: SessionInfo | null = null;

  constructor(prop: BaseProps) {
    super(prop);
    this.state = {
      msgs: [],
      showEmoji: false,
      messageInput: "",
      snackBarMsg: null,
      showSnackBar: false,
      showImage: undefined,
    };
    this.ref = React.createRef();
    this.listView = React.createRef();
    this.inputRef = React.createRef();
  }

  handleSubmit = (event: any) => {
    event.preventDefault();
    if (!this.state.messageInput) {
      return;
    }

    let msgTxt = this.state.messageInput;
    this.setState(
      {
        messageInput: "",
      },
      () => {
        let msg = new TextMessage();
        msg.content = msgTxt;
        msg.from = this.recordInfo?.user_id;
        msg.to = this.recordInfo?.kefu_id;
        msg.avatar = this.recordInfo?.avatar;
        new SendMesssgeRequest(msg).sendRequest(this.sendMessageCallback);
      }
    );
  };

  onFileChange = (event: any) => {
    let file = event.target.files[0];

    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", file, file.name);

    let msg = new ImageMessage(formData);
    msg.from = this.recordInfo?.user_id;
    msg.to = this.recordInfo?.kefu_id;
    msg.avatar = this.recordInfo?.avatar;
    new SendMesssgeRequest(msg).sendRequest(this.sendMessageCallback);
  };

  handleTextChange = (event: any) => {
    this.setState({
      messageInput: event.target.value,
    });
  };

  handleEmoji = (event: any) => {
    event.preventDefault();
    // Perform authentication logic here
    this.setState({
      showEmoji: !this.state.showEmoji,
    });
  };

  handleImageClick = (event: any) => {
    event.preventDefault();
    this.ref.current.click();
  };

  onSelectEmoji = (emojiContent: string) => {
    let text = this.state.messageInput;
    let selection = this.inputRef.current.selectionStart;
    if (selection < this.state.messageInput.length) {
      text = this.state.messageInput.substring(0, selection);
      text += emojiContent;
      text += this.state.messageInput.substring(
        selection,
        this.state.messageInput.length
      );
    } else {
      text += emojiContent;
    }
    this.setState(
      {
        showEmoji: !this.state.showEmoji,
        messageInput: text,
      },
      () => {
        this.inputRef.current.click();
      }
    );
  };

  sendMessageCallback = (
    success: boolean,
    msg: Message,
    replayMessage: Message | undefined,
    error: any
  ) => {
    if (!success) {
      this.showSnakebar("failedSendMSg");
      console.log(error);
      return;
    }

    this.appendMessage(msg, replayMessage);
  };

  appendMessage = (
    msg: Message | undefined,
    replayMsg?: Message | undefined
  ) => {
    if (!msg) {
      return;
    }
    let msgs = this.state.msgs;
    if (!msg) {
      msgs = [];
    }
    msgs.push(msg);
    if (replayMsg) {
      msgs.push(replayMsg);
    }

    this.setState(
      {
        msgs: msgs,
      },
      this.scrollList
    );
  };

  private onSessionUpdated = (sessionInfo: SessionInfo) => {
    this.recordInfo = sessionInfo;
  };

  componentDidMount(): void {
    new RecordRequest().sendRequest(
      (success: boolean, data: any, error: any) => {
        if (!success) {
          if (error instanceof ApiError) {
            this.showSnakebar(`${(error as ApiError).errorMessage}`);
          } else {
            this.showSnakebar(`Server error`);
          }
          console.log(error);
          return;
        }

        this.recordInfo = data.recordInfo;
        this.wsConn?.close();

        this.wsConn = new Connection(
          this.appendMessage,
          this.onSessionUpdated,
          () => {
            this.wsConn?.uploadRecordInfo(this.recordInfo);
          }
        );
        this.wsConn.connect();

        this.setState(
          {
            msgs: data.msgs,
          },
          this.scrollList
        );
      }
    );
  }

  private scrollList = () => {
    if (this.listView.current) {
      this.listView.current.scroll({
        top: this.listView.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  componentWillUnmount(): void {
    this.wsConn?.close();
  }

  showImage = (msg: Message | undefined) => {
    if (!msg || !(msg instanceof ImageMessage)) {
      return;
    }

    this.setState({
      showImage: (msg as ImageMessage).content,
    });
  };

  getStyle(): PageStyle {
    return PageStyle.black;
  }

  renderContent(t: any) {
    let lastTime = 0;
    let isShowTime = false;
    return (
      <div
        className={isMobile() ? "PageContent" : "MsgPcPageContent"}
        style={{ backgroundColor: "white" }}
      >
        <List className="MsgListContainer" ref={this.listView}>
          {this.state.msgs &&
            this.state.msgs.map((msg, index) => {
              if (msg instanceof SystemMessage) {
                return <SystemMessageItem message={msg} key={index} />;
              }
              if (msg.create_time) {
                let time = Date.parse(msg.create_time);
                if (time > lastTime + 1800000) {
                  isShowTime = true;
                  lastTime = time;
                } else {
                  isShowTime = false;
                }
              }

              if (msg?.from === this.recordInfo?.user_id) {
                return (
                  <MessageItemFromMe
                    message={msg}
                    key={index}
                    showTime={isShowTime}
                    onClickItem={this.showImage}
                  />
                );
              } else {
                return (
                  <MessageItemFromOther
                    message={msg}
                    key={index}
                    showTime={isShowTime}
                    onClickItem={this.showImage}
                  />
                );
              }
            })}
        </List>
        <div className="MsgInputAreadivider" />
        <div style={{ width: "100%", height: "auto" }}>
          <img
            src={emoji}
            className="MsgInputEmoji"
            onClick={this.handleEmoji}
            alt="emoji"
          />
          <img
            src={image}
            className="MsgInputEmoji"
            onClick={this.handleImageClick}
            alt="select file"
          />
        </div>
        <div className="MsgInputAreaContainer">
          <div style={{ display: "flex" }}>
            <TextField
              name="message"
              placeholder={t("msgHint") as string}
              multiline
              focused
              autoFocus
              rows={3}
              value={this.state.messageInput}
              onChange={this.handleTextChange}
              variant="standard"
              inputRef={this.inputRef}
              InputProps={{ disableUnderline: true }}
              className="MsgInput"
            ></TextField>
          </div>

          {this.state.showEmoji && (
            <EmojiPanel
              className={isMobile() ? "MsgEmojiPaneMobile" : "MsgEmojiPane"}
              onSelectEmoji={this.onSelectEmoji}
            />
          )}
          <div className="MsgButtonContainer">
            <div className="MsgButtonOccupie" />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="Button"
              onClick={this.handleSubmit}
            >
              {t("send")}
            </Button>
          </div>
        </div>

        <input
          name="the hidden input for open the file choose dialog"
          type="file"
          accept="image/*"
          ref={this.ref}
          onChange={this.onFileChange}
          style={{ display: "none" }}
        />
        {this.state.showImage && (
          <ImagePreview
            open
            images={[this.state.showImage]}
            onClose={() => {
              this.setState({ showImage: undefined });
            }}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Consultation);
