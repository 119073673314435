import React from "react";
import BaseProps from "../common/BaseProps";

import arrowDownIcon from "../images/arrow_down.png";
import { Goods } from "../data/model/Goods";
import "./ShopGoodsItem.scss"

interface State {}

interface Props extends BaseProps {
  goods: Goods | undefined | null;
}
export default class ShopGoodsItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    return (
      this.props.goods && 
      <div className="GoodsItem">
        <img src={this.props.goods.img ?? arrowDownIcon} className="GoodItemImage" />
        <div className="GoodsItemName">{this.props.goods.name}</div>
        <div className="GoodsItemPrice">{"£" + this.props.goods.price}</div>
        <div className="GoodsItemDesc">{this.props.goods.description}</div>
      </div>
    );
  }
}
