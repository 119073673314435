import { ReactNode } from "react";
import BaseComponet from "../common/BaseComponet";
import BaseProps from "../common/BaseProps";
import { isMobile } from "../common/utils";
import "./ImageTextItem.scss"

interface Props extends BaseProps {
    img: any
    title: string
    text: string
    direction: number
}
export default class ImageTextItem extends BaseComponet<Props> {

    render(): ReactNode {

        return <div className={this.props.direction > 0 ? "ImgTxtLeftContainer" : "ImgTxtRightContainer"}>
            <img className={isMobile() ? "ImgTxtItemImgMobile" : "ImgTxtItemImg"} src={this.props.img} alt="item" />
            <div className={isMobile() ? "ImgTxtItemTxtContainerMobile" : "ImgTxtItemTxtContainer"}>
                <div className={isMobile() ? "ImgTxtItemTitleMobile" : "ImgTxtItemTitle"}>{this.props.title}</div>
                <div className={isMobile() ? "ImgTxtItemTxtMobile" : "ImgTxtItemTxt"}>{this.props.text}</div>
            </div>
        </div>
    }
}