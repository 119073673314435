export class CheckResult {
  modelPath: string | undefined;
  norms: any[] | undefined;
  verties: any[] | undefined;

  constructor(modelUrl: string | undefined = undefined, norms:any[] | undefined = undefined) {
    this.modelPath = modelUrl;
    this.norms = norms;
    this.verties = undefined;
  }
}
