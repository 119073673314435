import React from "react";
import BaseProps from "../../common/BaseProps";
import ImageMessage from "../../data/customer_service/message/ImageMessage";
import Message from "../../data/customer_service/message/Message";
import TextMessage from "../../data/customer_service/message/TextMessage";
import csImage from "../../images/cs_avatar.png";
import "./MessageItemFromMe.scss";
import TextFieldWithEmoji from "../TextFieldWithEmoji";

export interface MessageItemProps extends BaseProps {
  message?: Message;
  showTime?: boolean;
  onClickItem?: (msg: Message | undefined) => void
}

export default class MessageItemFromMe extends React.Component<MessageItemProps> {
  render(): React.ReactNode {
    return (
      <div className="MessageContainer">
        {this.props.showTime && <div className="MessageTime">{this.props?.message?.create_time}</div>}
        <div className="MessageFromMeContainer">
          <div className="MessageItemContainer">
            {this.props.message && this.props.message.type === 1 && (
              <TextFieldWithEmoji text={(this.props.message as TextMessage).content} />
            )}

            {this.props.message && this.props.message.type === 3 && (
              <img
                className="MessageItemImage"
                src={(this.props.message as ImageMessage).content}
                onClick={() => {
                  this.props.onClickItem && this.props.onClickItem(this.props.message)
                }}
                alt="message item"
              ></img>
            )}
            <img src={csImage} className="MessageItemAvatar" alt="avatar" />
          </div>
        </div >
      </div >
    );
  }
}
