import { Box, Grid } from "@mui/material";
import BasePage, {
  BasePageState,
  PageStyle,
  mapDispatchToProps,
  mapStateToProps,
} from "../common/BasePage";
import BaseProps from "../common/BaseProps";
import { connect } from "react-redux";

interface Props extends BaseProps {}

interface State extends BasePageState {
}

class Shop2 extends BasePage<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showSnackBar: false,
      snackBarMsg: null,
    };
  }

  renderContent(t: any): React.ReactNode {
    return (
      <div className="Shop">
        <iframe src="https://3cc638-6.myshopify.com" style={{width: "100%", height:"100%"}} />
      </div>
    );
  }

  getStyle(): PageStyle {
    return PageStyle.black;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Shop2);
