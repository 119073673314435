import { createSlice } from "@reduxjs/toolkit"
const cameraSlice = createSlice({
    name: 'camera',
    initialState: {
        doCapture: false,
        cameraReady: false
    },
    reducers: {
        startCapture: state => {
            state.doCapture = true
        },
        finishCapture: state => {
            state.doCapture = false
        },
        cameraHasReady: state => {
            state.cameraReady = true
        },
        cameraClosed: state => {
            state.cameraReady = false
        }
    }
})

export const { startCapture, finishCapture, cameraHasReady, cameraClosed } = cameraSlice.actions
export default cameraSlice.reducer