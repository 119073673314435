import uuid from "react-uuid";

export default class Message {
  guid: string | undefined;
  id: string;
  type: number;
  from: number | undefined;
  to: number | undefined;
  create_time: string | undefined;
  avatar: string | undefined;
  constructor() {
    this.guid = uuid();
  }
}
