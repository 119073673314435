import axios from "axios";
import { CUSTOMER_SERVICE_URL, CUSTOMER_SERVICE_TOKEN } from "../../../config";
import ApiError from "../../error/ApiError";
import HttpError from "../../error/HttpError";
import { IHttpRequest } from "../../request/IHttpRequest";
import ImageMessage from "../message/ImageMessage";
import Message from "../message/Message";
import TextMessage from "../message/TextMessage";
import UploadImageRequest from "./UploadImageRequest";

export default class SendMesssgeRequest implements IHttpRequest {
  msg: Message;
  url: string;

  constructor(msg: Message) {
    this.msg = msg;
    this.url = `${CUSTOMER_SERVICE_URL}/api/mobile/service/send_message`;
  }

  async sendRequest(
    callback: (
      success: boolean,
      sentMessage: any,
      replyMessage: any,
      error?: any
    ) => void
  ) {
    if (this.msg.type === 1) {
      await this.sendTextMessage(this.msg as TextMessage, callback);
    } else if (this.msg.type === 3) {
      await this.sendImageMessage(this.msg as ImageMessage, callback);
    }
  }

  private async sendTextMessage(
    message: TextMessage,
    callback: (
      success: boolean,
      sentMessage: any,
      replyMessage: any,
      error?: any
    ) => void
  ) {
    try {
      let response = await axios.post(
        this.url,
        {
          avatar: message.avatar,
          msn: message.content,
          msn_type: message.type,
          to_user_id: message.to,
          is_send: 0,
          is_tourist: 0,
          user_id: message.from,
          other: {},
          type: message.type,
          guid: message.guid,
        },
        {
          headers: {
            "Authori-zation": `Bearer ${CUSTOMER_SERVICE_TOKEN}`,
          },
        }
      );

      if (response.status !== 200) {
        callback &&
          callback(false, undefined, undefined, new HttpError(response.status));
      } else if (response.data.status !== 200) {
        callback &&
          callback(
            false,
            undefined,
            new ApiError(response.data.status, response.data.msg)
          );
      } else {
        callback &&
          callback(
            true,
            this.msg,
            this.parseReplayMessage(response.data.data),
            undefined
          );
      }
    } catch (e) {
      callback && callback(false, undefined, e);
    }
  }

  private async sendImageMessage(
    message: ImageMessage,
    callback: (success: boolean, data?: any, error?: any) => void
  ) {
    let uploadMsg = new UploadImageRequest(message.imageInfo);
    await uploadMsg.sendRequest(
      async (success: boolean, data?: any, error?: any) => {
        if (!success) {
          callback && callback(success, data, error);
        } else {
          message.content = data;
          await this.sendTextMessage(message, callback);
        }
      }
    );
  }

  private parseReplayMessage(response: any) {
    if (!response.autoReply || !response.autoReplyData) {
      return;
    }

    let data = response.autoReplyData;
    let msg: TextMessage;
    if (data.msn_type === 1) {
      msg = new TextMessage();
    } else if (data.msn_type === 3) {
      msg = new ImageMessage();
    } else {
      msg = new TextMessage();
    }
    msg.guid = data.guid;
    msg.content = data.msn;
    msg.from = data.user_id;
    msg.to = data.to_user_id;
    msg.create_time = data.create_time;

    return msg;
  }
  cancel(): void {}
}
