import axios from "axios";
import { CUSTOMER_SERVICE_URL, CUSTOMER_SERVICE_TOKEN } from "../../../config";
import ApiError from "../../error/ApiError";
import HttpError from "../../error/HttpError";
import { IHttpRequest } from "../../request/IHttpRequest";

export default class UploadImageRequest implements IHttpRequest {
  url: string;
  data: any;
  constructor(image: any) {
    this.url = `${CUSTOMER_SERVICE_URL}/api/mobile/service/upload`;
    this.data = image;
  }

  async sendRequest(
    callback: (success: boolean, modelUrl?: any, error?: any) => void
  ) {
    try {
      let response = await axios.post(this.url, this.data, {
        headers: {
          "Authori-zation": `Bearer ${CUSTOMER_SERVICE_TOKEN}`,
        },
      });
      if (response.status !== 200) {
        callback(false, undefined, new HttpError(response.status));
      } else if (response.data.status !== 200) {
        callback(
          false,
          undefined,
          new ApiError(response.data.status, response.data.msg)
        );
      } else {
        callback(true, response.data.data.url, undefined);
      }
    } catch (e) {
      callback(false, undefined, e);
    }
  }
  cancel(): void { }
}
