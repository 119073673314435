import { get, set } from "local-storage";
import { CUSTOMER_SERVICE_TOKEN, CUSTOMER_SERVICE_HOST } from "../../config";
import ImageMessage from "./message/ImageMessage";
import Message from "./message/Message";
import SystemMessage from "./message/SystemMessage";
import TextMessage from "./message/TextMessage";
import { SessionInfo } from "./request/RecordRequest";
import { RequestData } from "./RequestData";
// {"type":"mssage_num","data":{"user_id":796,"num":1,"allNum":1,"recored":{"user_id":805,"to_user_id":796,"type":0,"online":1,"message":"\u53e4\u53e4\u602a\u602a","avatar":"https:\/\/demo40.crmeb.net\/uploads\/attach\/2020\/11\/20201110\/1b244797f8b86b4cc0665d75d160aa30.png","nickname":"cc002","message_type":1,"mssage_num":1,"add_time":1677818182,"update_time":1677818182,"is_tourist":0,"appid":"202116257358989495","create_time":"2023-03-03 12:36:22.904698","id":"44","user":{"id":796,"nickname":"cc002","remark_nickname":"","version":"0"},"_update_time":"2023-03-03 12:36"}}}
export default class Connection {
  websocket: WebSocket | null = null;
  pingHandler: any = null;
  isClosed: boolean = true;
  msgHandlers: any;
  manualClosed: boolean = false;
  onConnectCallback: (() => void) | undefined;
  onReceiveMessage: (msg: Message | undefined) => void | undefined;

  constructor(
    onReceiveMessage: (msg: Message | undefined) => void,
    onSessionUpdate: (sessionInfo: SessionInfo) => void,
    onConnect?: () => void
  ) {
    this.msgHandlers = {};
    this.msgHandlers["mssage_num"] = (response: any) => {
      // ignore this message
      // if (response.recored) {
      //   let msg = this.parseMessageNum(response.recored);
      //   this.onReceiveMessage && this.onReceiveMessage(msg);
      // }
    };
    this.msgHandlers["reply"] = (response: any) => {
      let msg = this.parseMessage(response);
      this.onReceiveMessage && this.onReceiveMessage(msg);
    };
    this.msgHandlers["to_transfer"] = (response: any) => {
      let session = this.transform(response);
      onSessionUpdate && onSessionUpdate(session);
    };

    this.onConnectCallback = onConnect;
    this.onReceiveMessage = onReceiveMessage;
  }

  connect() {
    if (!this.isClosed) {
      console.log("The socket is already connected.");
      return;
    }

    this.isClosed = false;
    this.manualClosed = false;
    this.websocket = new WebSocket(
      `wss://${CUSTOMER_SERVICE_HOST}/ws?type=user&form=mobile&token=${CUSTOMER_SERVICE_TOKEN}`
    );

    this.websocket.addEventListener("open", () => {
      console.log("WebSocket connection opened");
      this.ping();
      !this.isClosed && this.onConnectCallback && this.onConnectCallback();
    });

    this.websocket.addEventListener("message", (event) => {
      if (!event?.data) {
        return;
      }
      let data = JSON.parse(event.data);
      if (this.msgHandlers[data.type]) {
        this.msgHandlers[data.type](data.data);
      }
    });

    this.websocket.addEventListener("close", (event) => {
      console.log("WebSocket connection closed");
      this.isClosed = true;
      if (!this.manualClosed) {
        this.close();
        this.connect();
      }
    });

    this.websocket.addEventListener("error", (error) => {
      console.log("got an error:" + error);
      // this.close();
    });
  }

  close() {
    this.manualClosed = true;
    this.isClosed = true;
    if (this.pingHandler != null) {
      clearTimeout(this.pingHandler);
    }
    try {
      this.websocket?.close();
    } catch (e) {
      console.log(e);
    } finally {
      this.websocket = null;
    }
  }

  sendMessage<T>(msg: RequestData<T>) {
    this.websocket?.send(JSON.stringify(msg));
  }

  private parseMessage = (data: any) => {
    let msg: Message;
    if (data.msn_type === "1") {
      let textMsg = new TextMessage();
      textMsg.content = data.msn;
      msg = textMsg;
    } else if (data.msn_type === "3") {
      let imageMsg = new ImageMessage();
      imageMsg.content = data.msn;
      msg = imageMsg;
    } else {
      msg = new TextMessage();
      let textMsg = new TextMessage();
      textMsg.content = data.msn;
      msg = textMsg;
    }
    msg.to = data.to_user_id;
    msg.from = data.user_id;
    msg.guid = data.guid;
    msg.create_time = data.create_time;
    return msg;
  };

  // private parseMessageNum = (data: any) => {
  //   if (!data.message_type) {
  //     return;
  //   }

  //   let msg: TextMessage;
  //   switch (data.message_type) {
  //     case 1:
  //       msg = new TextMessage();
  //       break;
  //     case 3:
  //       msg = new ImageMessage();
  //       break;
  //     default:
  //       msg = new TextMessage();
  //   }

  //   msg.content = data.message;
  //   msg.from = data.to_user_id;
  //   msg.to = data.user_id;
  //   msg.create_time = data.create_time;
  //   return msg;
  // };

  public uploadRecordInfo(session: SessionInfo | null) {
    if (!session) {
      return;
    }
    let data = new RequestData("user", {
      to_user_id: session.kefu_id,
      uid: session.uid,
      nickname: session.nickName,
      avatar: session.avatar,
      type: 3,
    });

    this.sendMessage(data);
  }

  private ping() {
    this.pingHandler = setTimeout(() => {
      if (this.isClosed) {
        return;
      }

      this.sendMessage(new RequestData("ping", undefined));
      this.ping();
    }, 10000);
  }

  private transform(response: any) {
    let sessionInfo: SessionInfo = get("sessionInfo");
    sessionInfo.kefu_id = response.toUid;
    sessionInfo.kefu_nickName = response.nickname;
    set("sessionInfo", sessionInfo);
    this.onReceiveMessage &&
      this.onReceiveMessage(
        new SystemMessage(
          `The conversition has transfer to ${sessionInfo.kefu_nickName}`
        )
      );

    return sessionInfo;
  }

  //{"type":"to_transfer","data":{"toUid":795,"avatar":"https:\/\/demo40.crmeb.net\/uploads\/attach\/2020\/11\/20201110\/d4398c5d36757c1b1ed1f21202bea1c0.png","nickname":"cc001"}}
  //
  // {"type":"success","status":200,"data":{"appid":"202116257358989495"}}	69

  // //{"type":"user","data":{"to_user_id":795,"uid":"2023647199","nickname":"游客2023647199","avatar":"https://demo40.crmeb.net/uploads/attach/2020/11/20201110/d4398c5d36757c1b1ed1f21202bea1c0.png","type":"3"}}	202

  // {"type":"mssage_num","data":{"user_id":795,"num":0,"recored":{}}}	65

  //{// "type":"user","status":200}
  // {"type":"reply","data":{"to_user_id":"804","msn":"[em-expressionless]","msn_type":"1","add_time":1677601357,"appid":"202116257358989495","user_id":795,"guid":"df5dd9f1-f9f9-420c-9a71-b9881960286a","is_send":1,"type":1,"other":{},"create_time":"2023-03-01 00:22:37.122200","update_time":"2023-03-01 00:22:37.122212","id":"547","_add_time":"2023-03-01 00:22:37","nickname":"cc001","avatar":"https:\/\/demo40.crmeb.net\/uploads\/attach\/2020\/11\/20201110\/1f05bd27a6af2da438dc2bb689995fc5.png","recored":{"id":37,"appid":"202116257358989495","user_id":804,"to_user_id":795,"nickname":"cc001","avatar":"https:\/\/demo40.crmeb.net\/uploads\/attach\/2020\/11\/20201110\/1f05bd27a6af2da438dc2bb689995fc5.png","is_tourist":0,"online":1,"type":0,"add_time":1677600277,"update_time":1677601357,"delete_time":null,"mssage_num":0,"message":"[em-expressionless]","message_type":1,"user":{"id":795,"nickname":"cc001","remark_nickname":"","version":"0"},"_update_time":"2023-03-01 00:22"}}}
}
