import { Avatar, Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import React from "react";
import { isMobile } from '../common/utils';
import "./PreReadyModal.scss"
import RedCross from "../images/red_cross.png"
import GreenMark from "../images/green_mark.png"
import TipsRight from "../images/tips_right.png"
import TipsError1 from "../images/tips_error1.png"
import TipsError2 from "../images/tips_error2.png"
import TipsError3 from "../images/tips_error3.png"
import { Translation } from 'react-i18next';
import BaseProps from '../common/BaseProps';

interface Props extends BaseProps {
    onClickStart: () => void;
    onCancel?: () => void;
    open: boolean;
}

export default class PreReadyModal extends React.Component<Props> {
    render(): React.ReactNode {
        return <Translation>{t => <Modal open={this.props.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={'ModalContainer'}
            onClose={() => { this.props.onCancel && this.props.onCancel() }}>
            <div className={isMobile() ? 'ModalContentMobile' : 'ModalContent'}>
                <div className='TipsItemContainer'>
                    <div className='TipsImageContaner'>
                        <Avatar src={TipsRight} className="RoundImage" />
                        <img src={GreenMark} className="FlagImage" alt='flag' />
                    </div>
                    <div className='Tips'>{t("readyTips1")}</div>
                </div>

                <div className='MultiTipsItemContainer'>
                    <div className='TipsItemContainer'>
                        <div className='TipsImageContaner'>
                            <Avatar src={TipsError1} className="RoundImage" />
                            <img src={RedCross} className="FlagImage" alt='flag' />
                        </div>
                        <div className='Tips'>{t("readyTips2")}</div>
                    </div>
                    <div className='TipsItemContainer'>
                        <div className='TipsImageContaner'>
                            <Avatar src={TipsError2} className="RoundImage" />
                            <img src={RedCross} className="FlagImage" alt='flag' />
                        </div>
                        <div className='Tips'>{t("readyTips3")}</div>
                    </div>
                    <div className='TipsItemContainer'>
                        <div className='TipsImageContaner'>
                            <Avatar src={TipsError3} className="RoundImage" />
                            <img src={RedCross} className="FlagImage" alt='flag' />
                        </div>
                        <div className='Tips'>{t("readyTips4")}</div>
                    </div>
                </div>
                <Button variant="contained" className={'Button'} onClick={this.props.onClickStart}>{t("readyToStart")}</Button>
            </div>
        </Modal>
        }</Translation>
    }
}