import React from "react";
import ImageViewer from "react-simple-image-viewer";

import BaseProps from "../common/BaseProps";

// const images = [
//   { source: "path/to/image-1.jpg" },
//   { source: "path/to/image-2.jpg" },
// ];

interface Props extends BaseProps {
  open: boolean;
  onClose?: () => void;
  images: any[];
}

interface State {}

export default class ImagePreview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    return (
      <div style={{ display: "flex" }}>
        {this.props.open && this.props?.images && (
          <ImageViewer
            src={this.props?.images}
            currentIndex={0}
            onClose={this.props.onClose}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
            closeOnClickOutside={true}
          />
        )}
      </div>
    );
  }
}
