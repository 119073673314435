import TextMessage from "./TextMessage";

export default class ImageMessage extends TextMessage {
  imageInfo: any;
  constructor(imageInfo?: any) {
    super();
    this.type = 3;
    this.imageInfo = imageInfo;
  }
}
