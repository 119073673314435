import React from "react";
import BaseProps from "../common/BaseProps";
import { Emoji, findEmojis } from "../common/Emoji";
import "./TextFieldWithEmoji.scss";
import emojisImg from "../images/emojis.png";

interface Props extends BaseProps {
  text: string | undefined;
}

interface State {
  texts: any[];
  emojis: Emoji[] | undefined;
}

export default class TextFieldWithEmoji extends React.Component<Props, State> {
  constructor(prop: Props) {
    super(prop);
    
    let texts: any[] = [];
    if (!this.props.text) {
      this.state = {
        texts: texts,
        emojis: undefined,
      };
      return;
    }

    let emojis = findEmojis(this.props.text);

    if (emojis && emojis.length > 0) {
      let start = 0;
      let content = this.props.text as string;
      emojis.forEach((it) => {
        let index = it.index - it.emoji.value.length;
        if (index > start) {
          texts.push(content.substring(start, index));
        }
        texts.push(it.emoji);
        start = it.index;
      });
      if (start < content.length) {
        texts.push(content.substring(start, content.length));
      }
    } else {
      texts.push(this.props.text);
    }

    this.state = {
      texts: texts,
      emojis: emojis,
    };
  }

  renderContent(): any[] {
    let ret: any[] = [];
    this.state.texts.forEach((it, index) => {
      if (it instanceof Emoji) {
        ret.push(
          <div
            className="EmojiIcon"
            key={index}
            style={{
              backgroundImage: `url(${emojisImg})`,
              backgroundPosition: `${it.x}px ${it.y}px`,
            }}
          />
        );
      } else {
        ret.push(it);
      }
    });
    return ret;
  }

  render(): React.ReactNode {
    return (
      <div className={[this.props.className, "EmojiTextContent"].join(" ")}>
        {this.renderContent()}
      </div>
    );
  }
}
