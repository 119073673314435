import { Component, ReactNode } from "react";
import "./BottomBar.scss";
import company from "../images/company.png";
import openTime from "../images/time.png";
import tel from "../images/telephone.png";
import facebook from "../images/facebook.png";
import tiktok from "../images/tiktok.png";
import youtube from "../images/youtube.png";
import instagram from "../images/instagram.png";
import email from "../images/email.png";
import { isMobile } from "../common/utils";

import { Translation } from "react-i18next";

export default class BottomBar extends Component {
  render(): ReactNode {
    return (
      <Translation>
        {(t) => (
          <div
            className={isMobile() ? "bottomContainerMobile" : "bottomContainer"}
          >
            <div
              className={isMobile() ? "iconContainerMobile" : "iconContainer"}
            >
              <img src={company} className="icon" alt="company" />
              {t("address") + "Saunders House 52-53 The Mall, London W5 3TA"}
            </div>
            <div
              className={isMobile() ? "iconContainerMobile" : "iconContainer"}
            >
              <img src={openTime} className="icon" alt="openTime" />
              {t("contact_us") + t("openTime")}
            </div>
            <div
              className={isMobile() ? "iconContainerMobile" : "iconContainer"}
            >
              <img src={email} className="icon" alt="email" />
              {t("email") + "info@smartwig.co.uk"}
            </div>
            <div
              className={isMobile() ? "iconContainerMobile" : "iconContainer"}
            >
              <img src={tel} className="icon" alt="tel" />
              07939975364 / 02034053249
            </div>
            <div className="socialContainer">
              <a href="https://www.facebook.com/profile.php?id=61550084564893">
                <img src={facebook} className="socialIcon" alt="facebook" />
              </a>
              <a href="https://www.tiktok.com/@smart_wig?_t=8gEWSje5ga8&_r=1">
                <img src={tiktok} className="socialIcon" alt="tiktok" />
              </a>
              <a href="https://youtube.com/@SmartwigLTD?si=FU73hRxfR3RKrhRF">
                <img src={youtube} className="socialIcon" alt="youtube" />
              </a>
              <a href="https://instagram.com/smart_wig?igshid=OGQ5ZDc2ODk2ZA==">
                <img src={instagram} className="socialIcon" alt="instagram" />
              </a>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}
