import React from "react";
import BaseProps from "../common/BaseProps";
import "./EmojiPanel.scss";
import emojis from "../images/emojis.png";
import { Emoji, Emojis } from "../common/Emoji";

interface Props extends BaseProps {
  onSelectEmoji?: (emoji: string) => void;
}
export default class EmojiPanel extends React.Component<Props> {
  render(): React.ReactNode {
    return (
      <div className={this.props.className}>
        {
          Emojis.map((element: Emoji, index) => {
            return (
              <div
                key={index}
                className="EmojiItemContainer"
                onClick={() => {
                  this.props.onSelectEmoji &&
                    this.props.onSelectEmoji(element.value);
                }}
              >
                <div
                  className="EmojiItem"
                  style={{
                    backgroundImage: `url(${emojis})`,
                    backgroundPosition: `${element.x}px ${element.y}px`,
                  }}
                />
              </div>
            );
          })
          // <img className="EmojiItem" style={{
          //     backgroundImage: `url(${emojis})`, backgroundPosition: `0px -64px`
          // }} />
        }
      </div>
    );
  }
}
