import { ReactNode } from "react";
import { connect } from "react-redux";
import BasePage, {
  mapDispatchToProps,
  mapStateToProps,
  PageStyle,
} from "../common/BasePage";
import ImageTextItem from "../components/ImageTextItem";
import Tech1 from "../images/tech1.png";
import Tech2 from "../images/tech2.png";
import Tech3 from "../images/tech3.png";

class TechAndService extends BasePage {
  renderContent(t: any): ReactNode {
    return (
      <div>
        <ImageTextItem
          img={Tech1}
          title={t("techTitle1")}
          text={t("techContent1")}
          direction={1}
        />
        <ImageTextItem
          img={Tech2}
          title={t("techTitle2")}
          text={t("techContent2")}
          direction={-1}
        />
        <ImageTextItem
          img={Tech3}
          title={t("techTitle3")}
          text={t("techContent3")}
          direction={1}
        />
      </div>
    );
  }

  getStyle(): PageStyle {
    return PageStyle.black;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TechAndService);
