import { ReactNode } from "react";
import BasePage, {
  PageStyle,
  mapDispatchToProps,
  mapStateToProps,
} from "../common/BasePage";
import modelingDesign from "../images/modelingDesign.png";
import wigCare from "../images/wigCare.png";
import privateCustomization from "../images/privateCustomization.png";
import { connect } from "react-redux";
import "./Service.scss";

class Service extends BasePage {
  renderContent(t: any): ReactNode {
    return (
      <div className="PageContent" style={{ paddingTop: "1rem" }}>
        <a className="ServiceItemContainer" href="/">
          <div className="ServiceItemTitleContainer">
            <div className="ServiceItemTitle">{t("privateCustomization")}</div>
          </div>
          <img
            className="ServiceItemImage"
            src={privateCustomization}
            alt="service item"
          />
        </a>
        <a className="ServiceItemContainer" href="/">
          <div className="ServiceItemTitleContainer">
            <div className="ServiceItemTitle">{t("modelingDesign")}</div>
          </div>
          <img
            className="ServiceItemImage"
            src={modelingDesign}
            alt="service item"
          />
        </a>
        <a className="ServiceItemContainer" href="/">
          <div className="ServiceItemTitleContainer">
            <div className="ServiceItemTitle">{t("wigCare")}</div>
          </div>
          <img className="ServiceItemImage" src={wigCare} alt="service item" />
        </a>
      </div>
    );
  }

  getStyle(): PageStyle {
    return PageStyle.black;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Service);
