import { Box, Grid } from "@mui/material";
import React from "react";
import BaseProps from "../common/BaseProps";
import HairStyleItem from "./HairStyleItem";
import "../common/Common.scss";
import { Wig } from "../data/model/Wig";
import WigListRequest from "../data/request/WigListRequest";

interface State {
  wigs: Wig[] | null | undefined;
  selectedWig?: Wig | null;
}

interface Porps extends BaseProps {
  onSelectStyle?: (style: Wig) => void;
}

export default class HairStyleSelector extends React.Component<Porps, State> {
  constructor(props: Porps) {
    super(props);
    this.state = {
      wigs: undefined,
      selectedWig: null,
    };
  }
  fetchHairStyleList() {
    new WigListRequest().sendRequest(
      (success: boolean, wigs: Wig[] | undefined | null, e) => {
        if (success) {
          this.setState({
            wigs: wigs,
            selectedWig: this.randomWig(wigs && wigs.length > 0 ? wigs[0] : null)
          });
        } else {
          console.log(e);
        }
      }
    );
  }

  componentDidMount(): void {
    this.fetchHairStyleList();
  }

  onSelectStyle = (wig: Wig | null) => {
    if (wig) {
      this.setState({
        selectedWig: wig,
      });
    } else {
      this.setState({
        selectedWig: null,
      });
      return
    }
    if (wig.type <= 0 && !wig.modelUrl) {
      let random = this.randomWig(wig)
      wig.modelUrl = random?.modelUrl
    }
    
    wig && this.props.onSelectStyle && this.props.onSelectStyle(wig);
  };

  randomWig(origin: Wig | null): Wig | null {
    if (!this.state.wigs) {
      return origin
    }

    var index = Math.floor(Math.random() * this.state.wigs.length + 1)
    if (index >= this.state.wigs.length || index == 0) {
      index = 1
    }
    console.log("Randon wig index:" + index)
    let wig = this.state.wigs[index] 
    if (wig && wig.type > 0) {
      return wig
    }
    return origin
  }

  render(): React.ReactNode {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0.1} columns={4}>
          {this.state.wigs &&
            this.state.wigs.map((style: Wig, index) => {
              return (
                <Grid item key={index} xs={1} className="Center">
                  <HairStyleItem
                    wig={style}
                    isSelected={this.state.selectedWig?.type === style.type}
                    onSelect={this.onSelectStyle}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Box>
    );
  }
}
