import Message from "./Message";

export default class TextMessage extends Message {
  constructor() {
    super();
    this.type = 1;
  }

  content: string | undefined;
}


/**
 * 
 * {
  "msn": "ddddd",
  "msn_type": 1,
  "to_user_id": 1,
  "is_send": 0,
  "is_tourist": 0,
  "avatar": "https://demo40.crmeb.net/uploads/attach/2020/11/20201110/fcc758713087632dc785fff3d37db928.png",
  "user_id": "826",
  "other": {},
  "type": 0,
  "guid": "972a840b-1f76-497b-8ced-f41a3088b1d9"
}
 * 
 */