import { Box, Grid } from "@mui/material";
import BasePage, {
  BasePageState,
  PageStyle,
  mapDispatchToProps,
  mapStateToProps,
} from "../common/BasePage";
import BaseProps from "../common/BaseProps";
import { connect } from "react-redux";
import GoodsRequest from "../data/request/GoodsRequest";
import { Goods } from "../data/model/Goods";
import "./Shop.scss";
import ShopGoodsItem from "../components/ShopGoodsItem";

interface Props extends BaseProps {}

interface State extends BasePageState {
  goods: Goods[] | undefined | null;
}

class Shop extends BasePage<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      goods: undefined,
      showSnackBar: false,
      snackBarMsg: null,
    };
  }

  componentDidMount(): void {
    this.getGoodsList();
  }

  getGoodsList() {
    new GoodsRequest().sendRequest(
      (success: Boolean, goods: Goods[] | undefined | null, e) => {
        if (success) {
          this.setState({ goods: goods });
        }
      }
    );
  }

  renderContent(t: any): React.ReactNode {
    return (
      <div className="Shop">
        <div className="ShopTip"> {t("shopTips")}</div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0.05} columns={2} sx ={{px: 1}}>
            {this.state.goods &&
              this.state.goods.map((goods: Goods, index) => {
                return (
                  <Grid item key={index} xs={1} className="Center">
                    <ShopGoodsItem goods={goods} />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </div>
    );
  }

  getStyle(): PageStyle {
    return PageStyle.black;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
