import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Splash from "./pages/Splash";
import "./i18n";
import { BASE_PATH } from "./config";
import { SPLASH, HOME, ABOUT, CONSULATION, TECH, SERVICE, SHOP, SHOP2 } from "./RouterPath";
import Consultation from "./pages/Consultation";
import TechAndService from "./pages/TechAndService";
import Service from "./pages/Service";
import Test from "./pages/Test";
import Shop from "./pages/Shop";
import Shop2 from "./pages/Shop2";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path={BASE_PATH} element={<Splash />} />
        <Route path={SPLASH} element={<Splash />} />
        <Route path={HOME} element={<Home />} />
        <Route path={ABOUT} element={<AboutUs />} />
        <Route path={CONSULATION} element={<Consultation />} />
        <Route path={TECH} element={<TechAndService />} />
        <Route path={SERVICE} element={<Service />} />
        <Route path={SHOP} element={<Shop />} />
        <Route path={SHOP2} element={<Shop2 />} />
        <Route path="/test" element={<Test />} />
      </Routes>
    </Router>
  );
}

export default App;
