import React from "react";
import SystemMessage from "../../data/customer_service/message/SystemMessage";
import { MessageItemProps } from "./MessageItemFromMe";
import "./MessageItemFromOther.scss";

export default class SystemMessageItem extends React.Component<MessageItemProps> {

    render(): React.ReactNode {
        let msg = this.props?.message as SystemMessage
        return <div className="MessageTime">{msg.content}</div>
    }
}