import axios from "axios";
import { CUSTOMER_SERVICE_URL, CUSTOMER_SERVICE_TOKEN } from "../../../config";
import HttpError from "../../error/HttpError";
import { IHttpRequest } from "../../request/IHttpRequest";
import { get, set } from "local-storage";
import TextMessage from "../message/TextMessage";
import Message from "../message/Message";
import ImageMessage from "../message/ImageMessage";
import ApiError from "../../error/ApiError";

export default class RecordRequest implements IHttpRequest {
  url: string;

  constructor() {
    this.url = `${CUSTOMER_SERVICE_URL}/api/mobile/user/record`;
  }

  async sendRequest(
    callback: (success: boolean, modelUrl?: any, error?: any) => void
  ) {
    try {
      let recordInfo: SessionInfo = get("sessionInfo");
      let response = await axios.get(this.url, {
        params: {
          limit: 20,
          uid: recordInfo?.uid ?? "",
          kefu_id: recordInfo?.kefu_id ?? 0,
          toUserId: recordInfo?.kefu_id ?? 1,
          type: 0,
        },
        headers: {
          // "Access-Control-Allow-Origin": "*",
          "Authori-zation": `Bearer ${CUSTOMER_SERVICE_TOKEN}`,
        },
      });

      if (response.status !== axios.HttpStatusCode.Ok) {
        callback &&
          callback(false, undefined, new HttpError(axios.HttpStatusCode.Ok));
      } else if (response.data.status === 200) {
        let record = new SessionInfo();
        record.uid = response.data?.data?.uid ?? "";
        record.kefu_id = response.data?.data?.to_user_id ?? 0;
        record.user_id = response.data?.data?.user_id ?? 0;
        record.avatar = response.data?.data?.avatar ?? "";
        record.nickName = response.data?.data?.nickname ?? "";
        record.kefu_nickName = response.data?.data?.to_user_nickname ?? "";
        set("sessionInfo", record);

        if (response.data.data?.welcome) {
          let welcome = response.data.data?.welcome;
          let msg = new TextMessage();
          msg.id = welcome.id;
          msg.content = welcome.msn;
          msg.create_time = welcome.create_time;
          msg.from = welcome.user_id;
          msg.to = welcome.to_user_id;

          callback &&
            callback(true, { msgs: [msg], recordInfo: record }, undefined);
        } else if (response.data.data?.serviceList) {
          callback &&
            callback(
              true,
              {
                msgs: this.parseMsgs(response.data.data?.serviceList),
                recordInfo: record,
              },
              undefined
            );
        }
      } else {
        callback &&
          callback(
            false,
            undefined,
            new ApiError(response.data.status, response.data.msg)
          );
      }
    } catch (e) {
      callback && callback(false, undefined, e);
    }
  }

  cancel(): void {
    throw new Error("Method not implemented.");
  }

  private parseMsgs(serviceList: []): Message[] {
    let msgs: Message[] = [];
    serviceList.forEach((it: any) => {
      let msg: Message | undefined = undefined;
      if (it.msn_type === 1) {
        msg = new TextMessage();
        msg.create_time = it._add_time;
        msg.from = it.user_id;
        msg.to = it.to_user_id;
        msg.id = it.id;
        (msg as TextMessage).content = it.msn;
      } else if (it.msn_type === 3) {
        msg = new ImageMessage(undefined);
        msg.create_time = it._add_time;
        msg.from = it.user_id;
        msg.to = it.to_user_id;
        msg.id = it.id;
        (msg as ImageMessage).content = it.msn;
      }
      msg && msgs.push(msg);
    });
    return msgs;
  }
}

export class SessionInfo {
  uid: string;
  kefu_id: number;
  user_id: number;
  avatar: string;
  nickName: string;
  kefu_nickName: string;
}
