import { IHttpRequest } from "./IHttpRequest";
import { API_PATH, IS_DEMO } from "../../config";
import axios from "axios";
import HttpError from "../error/HttpError";
import ApiError from "../error/ApiError";
import { OK } from "../ApiErrorCode";
import { Wig, generateDemoWigs } from "../model/Wig";

export default class WigListRequest implements IHttpRequest {
  url: string;
  callback: ((success: boolean, data?: any, error?: any) => void) | undefined;

  constructor() {
    this.url = API_PATH + "/getwigs";
  }
  async sendRequest(
    callback: (success: boolean, data?: any, error?: any) => void
  ) {
    this.callback = callback;
    try {
      let response = await axios.get(this.url);
      if (response.status !== axios.HttpStatusCode.Ok) {
        this.onFailed(new HttpError(axios.HttpStatusCode.Ok));
      } else {
        if (response.data.code === OK) {
          callback &&
            callback(true, this.parseWigs(response.data.data), undefined);
        } else {
          this.onFailed(
            new ApiError(response.data.code, response.data.message)
          );
        }
      }
    } catch (e) {
      this.onFailed(e);
    }
  }

  onFailed(error: any) {
    if (IS_DEMO) {
      this.callback && this.callback(true, generateDemoWigs());
    } else {
      this.callback && this.callback(false, undefined, error);
    }
  }

  cancel(): void {
    this.callback = undefined;
  }

  private parseWigs(data: any[]): Wig[] | null {
    let wigs: Wig[] = [];
    data.forEach((it) => {
      let wig = new Wig();
      wig.cover = it.cover;
      wig.modelUrl = it.modelUrl;
      wig.pk = it.pk;
      wig.name = it.name;
      wig.type = it.type;
      wig.textureUrl = it.textureUrl;
      wigs.push(wig);
    });
    return wigs;
  }
}
