import React from "react";
import "./Splash.scss";
import splash1 from "../images/splash1.gif";
import splash2 from "../images/splash2.gif";
import splash3 from "../images/splash3.png";
import facebook from "../images/icon_facebook.png";
import whatsApp from "../images/icon_whatsapp.png";
import { isMobile } from "../common/utils";
import { Button, Icon } from "@mui/material";
import { CONSULATION, HOME } from "../RouterPath";
import BasePage, {
  mapDispatchToProps,
  mapStateToProps,
  PageStyle,
} from "../common/BasePage";
import { connect } from "react-redux";

class Splash extends BasePage {
  enterToHome = () => {
    window.location.href = HOME;
  };

  consultation = () => {
    window.location.href = CONSULATION;
  };

  renderContent(t: any): React.ReactNode {
    return (
      <div className="Splash">
        <div className={isMobile() ? "SloganMobile" : "Slogan"}>
          {t("slogan")}
        </div>
        <div className={isMobile() ? "SplashDescMobile" : "SplashDesc"}>
          {t("splashDesc")}
        </div>
        <div className="BeforeAfter">{t("beforeAfter")}</div>
        <div style={{ marginBottom: "1rem", padding: "1%" }}>
          <img
            src={splash3}
            alt="splashImg"
            className="SplashImg"
            style={{ maxWidth: "94%", width: "94%", marginLeft: "2%" }}
          />
          <img src={splash1} className="SplashImg" alt="splashImg1" />
          <img src={splash2} className="SplashImg" alt="splashImg2" />
          <div className="SplashLabel">{t("splashLabel1")}</div>
          <div className="SplashLabel">{t("splashLabel2")}</div>
        </div>

        <div className="SplashBtnContainer">
          <Button
            variant="contained"
            className="GoHomeButton"
            onClick={this.enterToHome}
          >
            {t("getStart")}
          </Button>

          <Button
            variant="contained"
            className="GoHomeButton"
            href="https://wa.me/message/KDWW2JXV5J4WP1"
            startIcon={
              <Icon className="SplashButtonIcon">
                <img src={whatsApp}  className="SplashButtonIcon"/>
              </Icon>
            }
          >
            {t("customerService")}
          </Button>

          <Button
            variant="contained"
            className="GoHomeButton"
            href="https://www.facebook.com/profile.php?id=61550084564893"
            startIcon={
              <Icon className="SplashButtonIcon">
                <img src={facebook} className="SplashButtonIcon" />
              </Icon>
            }
          >
            {t("appointment")}
          </Button>
        </div>
        <div className="SplashTips">{t("splashTip1")}</div>
      </div>
    );
  }

  getStyle(): PageStyle {
    return PageStyle.black;
  }

  showBottom(): boolean {
    return true;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Splash);
