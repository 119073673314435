import axios from "axios";
import { API_PATH } from "../../config";
import { IHttpRequest } from "./IHttpRequest";
import HttpError from "../error/HttpError";
import { OK } from "../ApiErrorCode";
import ApiError from "../error/ApiError";
import { Goods } from "../model/Goods";

export default class GoodsRequest implements IHttpRequest {
  url: string;
  callback: ((success: boolean, data?: any, error?: any) => void) | undefined;

  constructor() {
    this.url = API_PATH + "/getgoods";
  }
  async sendRequest(
    callback: (success: boolean, data?: any, error?: any) => void
  ) {
    try {
      this.callback = callback;
      let response = await axios.get(this.url);
      if (response.status !== axios.HttpStatusCode.Ok) {
        this.callback &&
          this.callback(
            false,
            undefined,
            new HttpError(axios.HttpStatusCode.Ok)
          );
      } else {
        if (response.data.code === OK) {
          this.callback &&
            this.callback(true, this.parseGoods(response.data.data), undefined);
        } else {
          this.callback &&
            this.callback(
              false,
              undefined,
              new ApiError(response.data.code, response.data.message)
            );
        }
      }
    } catch (e) {
      this.callback && callback(false, undefined, e);
    }
  }

  cancel(): void {
    this.callback = undefined;
  }

  private parseGoods(data: any[]): Goods[] | null {
    let allGoods: Goods[] = [];
    data.forEach((it) => {
      let goods = new Goods();
      goods.description = it.description;
      goods.img = it.img;
      goods.price = it.price;
      goods.name = it.name;
      allGoods.push(goods);
    });
    return allGoods;
  }
}