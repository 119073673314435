import { createSlice } from "@reduxjs/toolkit"
const captureSlice = createSlice({
    name: 'capture',
    initialState: {
        capturedImage: undefined,
        showLoading: false
    },
    reducers: {
        imageCaptured: (state: any, action: any) => {
            state.capturedImage = action.payload
            state.showLoading = Boolean(state.capturedImage)
        },

        finishTransform: state => {
            state.showLoading = false
        },

        unmounted: state => {
            state.capturedImage = undefined
            state.showLoading = false
        }
    }
})

export const { imageCaptured, finishTransform } = captureSlice.actions

export default captureSlice.reducer