import { createSlice } from "@reduxjs/toolkit"
const homeSlice = createSlice({
    name: 'home',
    initialState: {
        isShowContactUs: false
    },
    reducers: {
        showContactUs: state => {
            state.isShowContactUs = true
        },

        dismissContactUs: state => {
            state.isShowContactUs = false
        },
    }
})

export const { showContactUs, dismissContactUs } = homeSlice.actions

export default homeSlice.reducer