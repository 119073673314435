import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Modal } from "@mui/material";
import React from "react";
import { Translation } from "react-i18next";
import BaseProps from "../common/BaseProps";
import { isMobile } from "../common/utils";
import CameraView from "./CameraView";
import "./CaptureModal.scss"
import CaptureIcon from "../images/capture_icon.png"
import GreenMark from "../images/green_mark.png"
import { connect } from "react-redux";
import { startCapture } from "../reducer/CameraReducer";
import { finishTransform } from "../reducer/CaptureReducer";
import Transforming from "./Transforming";
import { CheckResult } from "../data/model/CheckResult";

interface Props extends BaseProps {
    onFinish: (modelUrl: CheckResult | undefined, isSuccess: boolean, isCancel: boolean) => void;
    open: boolean
}

interface State {
    transformResult?: CheckResult;
    showError: boolean;
    finish: boolean;
    showCapture: boolean;
}

class CaptureModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            transformResult: undefined,
            showError: false,
            finish: false,
            showCapture: true
        }
    }

    onClickViewResult = () => {
        this.props.onFinish(this.state.transformResult, Boolean(this.state.transformResult), false)
    }

    onClickStartTransform = () => {
        let { startCapture } = (this.props as any)
        startCapture()
    }

    onFinishTransforming = (success: boolean, result?: CheckResult) => {
        const { finishTransform } = this.props as any
        this.setState({
            transformResult: result,
            finish: success,
            showError: !success,
            showCapture: false
        }, () => {
            finishTransform && finishTransform()
        })
    }

    onCancel = () => {
        this.props.onFinish(undefined, false, true)
    }

    onRetry = () => {
        this.setState({
            showError: false,
            finish: false,
            showCapture: true,
        })
    }

    //#region render
    renderCaptureView(): React.ReactNode {
        const { cameraIsReady } = this.props as any
        return <Translation>{t => (
            <div className="CaptureModelContent">
                <img src={CaptureIcon} className="CaptureIcon" alt="CaptureIcon" />
                <CameraView className="CaptureSurface" />
                <div className="CaptureTips">{t("captureTips")}</div>
                <Button disabled={!cameraIsReady} variant="contained" className="Button" onClick={this.onClickStartTransform}>{t("readyToStart")}</Button>
            </div>
        )}
        </Translation>
    }

    renderLoadingView(): React.ReactNode {
        const { capturedImage } = this.props as any
        return <Transforming imageData={capturedImage} onFinishTransform={this.onFinishTransforming} />
    }

    renderFinishView(): React.ReactNode {
        return <Translation>{t =>
            <div className="CaptureModelContent">
                <img src={GreenMark} className="CaptureFinishIcon" alt="Capture Finished" />
                <div className="CaptureFinishTips">{t("transformFinish")}</div>
                <Button variant="contained" className={'Button'} onClick={this.onClickViewResult}>{t("viewResult")}</Button>
            </div>
        }</Translation>

    }

    renderErrorView(): React.ReactNode {
        return <Translation>{t =>
            <Dialog
                open
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">
                    {t("")}
                </DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("transformError")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onRetry} autoFocus>{t("retry")}</Button>
                    <Button onClick={this.onCancel} >{t("cancel")}</Button>
                </DialogActions>
            </Dialog>
        }</Translation>
    }

    render(): React.ReactNode {
        const { showLoading } = this.props as any
        return <Modal open={this.props.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={'ModalContainer'}>
            <div className={isMobile() ? 'ModalContentMobile' : 'ModalContent'}>
                {!showLoading && this.state.showCapture && this.renderCaptureView()}
                {showLoading && this.renderLoadingView()}
                {this.state.showError && this.renderErrorView()}
                {this.state.finish && this.renderFinishView()}
            </div>
        </Modal>
    }
    //#endregion
}

const mapStateToProps = (state: any) => ({
    capturedImage: state.capture.capturedImage,
    showLoading: state.capture.showLoading,
    cameraIsReady: state.camera.cameraReady
})
const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
    startCapture: () => {
        dispatch(startCapture())
    },
    finishTransform: () => {
        dispatch(finishTransform())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(CaptureModal)