import React from "react";
import { connect } from "react-redux";
import BasePage, {
  mapDispatchToProps,
  mapStateToProps,
  PageStyle,
} from "../common/BasePage";
import { isMobile } from "../common/utils";
import About2 from "../images/about1.png";
import About3 from "../images/about2.png";
import About1 from "../images/tech2.png";
import "./AboutUs.scss";

/*<div>
Result
<ThreeDViewer src="/upload/NeilArmstrong.glb" />
<ThreeDViewer src="/upload/coffeemat.glb" meshDecoder="https://unpkg.com/meshoptimizer/meshopt_decoder.js" />
<ThreeDViewer src="/upload/reflective-sphere.gltf" />

</div>*/
// src="https://raw.githubusercontent.com/dwqdaiwenqi/react-3d-viewer/master/site/src/lib/model/destiny.obj" />
class AboutUs extends BasePage {
  renderContent(t: any): React.ReactNode {
    return (
      <div className="PageContent">
        <div
          className={
            isMobile() ? "AboutImgContainerMobile" : "AboutImgContainer"
          }
        >
          <img src={About1} className="AboutUsImg" alt="about" />
          <img src={About2} className="AboutUsImg" alt="about" />

          <div
            style={{ display: "flex", flexDirection: "row", height: "auto" }}
          >
            <div style={{ width: "35%", height: "0px" }}></div>
            <img src={About3} className="AboutUsImg1" alt="about" />

            <div className={isMobile() ? "triangleMobile" : "triangle"} />

            <div
              className={
                isMobile()
                  ? "AboutContentContainerMobile"
                  : "AboutContentContainer"
              }
            >
              <div className="AboutTitle">{t("aboutus")}</div>
              <div className={isMobile() ? "AboutDescMobile" : "AboutDesc"}>
                {t("aboutUsDesc")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  showBottom(): boolean {
    return true;
  }

  getStyle(): PageStyle {
    return PageStyle.black;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
