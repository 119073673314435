import React from "react";
import ImageMessage from "../../data/customer_service/message/ImageMessage";
import TextMessage from "../../data/customer_service/message/TextMessage";
import csImage from "../../images/customer_service.png";
import { MessageItemProps } from "./MessageItemFromMe";
import "./MessageItemFromOther.scss";
import TextFieldWithEmoji from "../TextFieldWithEmoji";

export default class MessageItemFromOther extends React.Component<MessageItemProps> {
  render(): React.ReactNode {
    return (
      <div className="MessageContainer">
        {this.props.showTime && (
          <div className="MessageTime">{this.props?.message?.create_time}</div>
        )}
        <div className="MessageFromOtherContainer">
          <div className="MessageItemContainer">
            <img
              src={csImage}
              className="MessageItemAvatar"
              onClick={() => {
                this.props.onClickItem &&
                  this.props.onClickItem(this.props.message);
              }}
              alt="MessageItemAvatar"
            />
            {this.props.message && this.props.message.type === 1 && (
              <TextFieldWithEmoji
                text={(this.props.message as TextMessage).content}
              />
            )}
            {this.props.message && this.props.message.type === 3 && (
              <img
                className="MessageItemImage"
                src={(this.props.message as ImageMessage).content}
                onClick={() => {
                  this.props.onClickItem &&
                    this.props.onClickItem(this.props.message);
                }}
                alt="message item"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
