export class Emoji {
  value: string;
  x: number;
  y: number;
  constructor(v: string, x: number, y: number) {
    this.value = v;
    this.x = x;
    this.y = y;
  }
}

export const Emojis = [
  new Emoji("[em-tlj-1]", 0, 0),
  new Emoji("[em-tlj-2]", 0, -64),
  new Emoji("[em-tlj-3]", 0, -128),
  new Emoji("[em-tlj-4]", 0, -192),
  new Emoji("[em-tlj-5]", 0, -64 * 4),
  new Emoji("[em-tlj-6]", 0, -64 * 5),
  new Emoji("[em-tlj-7]", 0, -64 * 6),
  new Emoji("[em-tlj-8]", 0, -64 * 7),
  new Emoji("[em-tlj-9]", 0, -64 * 8),
  new Emoji("[em-tlj-10]", 0, -64 * 9),
  new Emoji("[em-tlj-11]", 0, -64 * 10),
  new Emoji("[em-tlj-12]", 0, -64 * 11),
  new Emoji("[em-tlj-13]", 0, -64 * 12),
  new Emoji("[em-tlj-14]", 0, -64 * 13),
  new Emoji("[em-tlj-15]", 0, -64 * 14),
  new Emoji("[em-tlj-16]", 0, -64 * 15),
  new Emoji("[em-tlj-17]", 0, -64 * 16),
  new Emoji("[em-tlj-18]", 0, -64 * 17),
  new Emoji("[em-tlj-19]", 0, -64 * 18),
  new Emoji("[em-tlj-20]", 0, -64 * 19),
  new Emoji("[em-tlj-21]", 0, -64 * 20),
  new Emoji("[em-tlj-22]", 0, -64 * 21),
  new Emoji("[em-tlj-23]", 0, -64 * 22),
  new Emoji("[em-tlj-24]", 0, -64 * 23),
  new Emoji("[em-tlj-25]", 0, -64 * 24),
  new Emoji("[em-tlj-26]", 0, -64 * 25),
  new Emoji("[em-tlj-27]", 0, -64 * 26),
  new Emoji("[em-tlj-28]", 0, -64 * 27),
  new Emoji("[em-tlj-29]", 0, -64 * 28),
  new Emoji("[em-tlj-30]", 0, -64 * 29),
  new Emoji("[em-tlj-31]", 0, -64 * 30),
  new Emoji("[em-tlj-32]", 0, -64 * 31),
  new Emoji("[em-tlj-33]", 0, -64 * 32),
  new Emoji("[em-tlj-34]", 0, -64 * 33),
  new Emoji("[em-tlj-35]", 0, -64 * 34),
  new Emoji("[em-tlj-36]", 0, -64 * 35),
  new Emoji("[em-tlj-37]", 0, -64 * 36),
  new Emoji("[em-tlj-38]", 0, -64 * 37),
  new Emoji("[em-tlj-39]", 0, -64 * 38),
  new Emoji("[em-tlj-40]", 0, -64 * 39),
  new Emoji("[em-tlj-41]", 0, -64 * 40),

  new Emoji("[em-tlj-42]", -64, -64 * 0),
  new Emoji("[em-tlj-43]", -64, -64 * 1),
  new Emoji("[em-tlj-44]", -64, -64 * 2),
  new Emoji("[em-tlj-45]", -64, -64 * 3),
  new Emoji("[em-tlj-46]", -64, -64 * 4),
  new Emoji("[em-tlj-47]", -64, -64 * 5),
  new Emoji("[em-tlj-48]", -64, -64 * 6),
  new Emoji("[em-tlj-49]", -64, -64 * 7),
  new Emoji("[em-tlj-50]", -64, -64 * 8),
  new Emoji("[em-tlj-51]", -64, -64 * 9),
  new Emoji("[em-tlj-52]", -64, -64 * 10),
  new Emoji("[em-tlj-53]", -64, -64 * 11),
  new Emoji("[em-tlj-54]", -64, -64 * 12),
  new Emoji("[em-tlj-55]", -64, -64 * 13),
  new Emoji("[em-tlj-56]", -64, -64 * 14),
  new Emoji("[em-tlj-57]", -64, -64 * 15),
  new Emoji("[em-tlj-58]", -64, -64 * 16),
  new Emoji("[em-tlj-59]", -64, -64 * 17),
  new Emoji("[em-tlj-60]", -64, -64 * 18),
  new Emoji("[em-tlj-61]", -64, -64 * 19),
  new Emoji("[em-tlj-62]", -64, -64 * 20),
  new Emoji("[em-tlj-63]", -64, -64 * 21),
  new Emoji("[em-tlj-64]", -64, -64 * 22),
  new Emoji("[em-tlj-65]", -64, -64 * 23),
  new Emoji("[em-tlj-66]", -64, -64 * 24),
  new Emoji("[em-tlj-67]", -64, -64 * 25),
  new Emoji("[em-tlj-68]", -64, -64 * 26),
  new Emoji("[em-tlj-69]", -64, -64 * 27),
  new Emoji("[em-tlj-70]", -64, -64 * 28),
  new Emoji("[em-tlj-71]", -64, -64 * 29),
  new Emoji("[em-tlj-72]", -64, -64 * 30),
  new Emoji("[em-tlj-73]", -64, -64 * 31),
  new Emoji("[em-tlj-74]", -64, -64 * 32),
  new Emoji("[em-tlj-75]", -64, -64 * 33),
  new Emoji("[em-tlj-76]", -64, -64 * 34),
  new Emoji("[em-tlj-77]", -64, -64 * 35),
  new Emoji("[em-tlj-78]", -64, -64 * 36),
  new Emoji("[em-tlj-79]", -64, -64 * 37),
  new Emoji("[em-tlj-80]", -64, -64 * 38),
  new Emoji("[em-tlj-81]", -64, -64 * 39),
  new Emoji("[em-tlj-82]", -64, -64 * 40),
  new Emoji("[em-tlj-83]", -128, 0),
  new Emoji("[em-tlj-84]", -128, -64),
  new Emoji("[em-tlj-85]", -128, -128),
  new Emoji("[em-tlj-86]", -128, -192),
  new Emoji("[em-tlj-87]", -128, -64 * 4),
  new Emoji("[em-tlj-88]", -128, -64 * 5),
  new Emoji("[em-tlj-89]", -128, -64 * 6),
  new Emoji("[em-tlj-90]", -128, -64 * 7),
  new Emoji("[em-tlj-91]", -128, -64 * 8),
  new Emoji("[em-tlj-92]", -128, -64 * 9),
  new Emoji("[em-tlj-93]", -128, -64 * 10),
  new Emoji("[em-tlj-94]", -128, -64 * 11),
  new Emoji("[em-tlj-95]", -128, -64 * 12),
  new Emoji("[em-tlj-96]", -128, -64 * 13),

  new Emoji("[em-smile]", -64 * 26, -64 * 19),
  new Emoji("[em-laughing]", -64 * 26, -64 * 21),
  new Emoji("[em-blush]", -64 * 26, -64 * 25),
  new Emoji("[em-smiley]", -64 * 26, -64 * 18),
  new Emoji("[em-smirk]", -64 * 26, -64 * 30),
  new Emoji("[em-heart_eyes]", -64 * 26, -64 * 28),

  new Emoji("[em-kissing_heart]", -64 * 26, -64 * 39),
  new Emoji("[em-kissing_closed_eyes]", -64 * 27, -64 * 0),
  new Emoji("[em-flushed]", -64 * 27, -64 * 25),
  new Emoji("[em-relieved]", -64 * 26, -64 * 27),
  new Emoji("[em-satisfied]", -64 * 26, -64 * 30),
  new Emoji("[em-grin]", -64 * 26, -64 * 16),
  new Emoji("[em-wink]", -64 * 26, -64 * 24),
  new Emoji("[em-stuck_out_tongue_winking_eye]", -64 * 27, -64 * 2),

  new Emoji("[em-stuck_out_tongue_closed_eyes]", -64 * 27, -64 * 3),
  new Emoji("[em-grinning]", -64 * 26, -64 * 15),
  new Emoji("[em-kissing]", -64 * 26, -64 * 38),
  new Emoji("[em-kissing_smiling_eyes]", -64 * 26, -64 * 40),
  new Emoji("[em-stuck_out_tongue]", -64 * 27, -64 * 1),
  new Emoji("[em-sleeping]", -64 * 27, -64 * 26),
  new Emoji("[em-worried]", -64 * 27, -64 * 5),
  new Emoji("[em-frowning]", -64 * 27, -64 * 12),
  new Emoji("[em-anguished]", -64 * 27, -64 * 13),
  new Emoji("[em-open_mouth]", -64 * 27, -64 * 20),

  new Emoji("[em-grimacing]", -64 * 27, -64 * 18),
  new Emoji("[em-confused]", -64 * 26, -64 * 35),
  new Emoji("[em-hushed]", -64 * 27, -64 * 21),
  new Emoji("[em-expressionless]", -64 * 26, -64 * 32),
  new Emoji("[em-unamused]", -64 * 26, -64 * 33),
  new Emoji("[em-sweat_smile]", -64 * 26, -64 * 20),
  new Emoji("[em-sweat]", -64 * 26, -64 * 34),
  new Emoji("[em-disappointed_relieved]", -64 * 27, -64 * 17),
  new Emoji("[em-weary]", -64 * 27, -64 * 15),
  new Emoji("[em-pensive]", -64 * 26, -64 * 35),

  new Emoji("[em-disappointed]", -64 * 27, -64 * 4),
  new Emoji("[em-confounded]", -64 * 26, -64 * 36),
  new Emoji("[em-fearful]", -64 * 27, -64 * 14),
  new Emoji("[em-cold_sweat]", -64 * 27, -64 * 22),
  new Emoji("[em-persevere]", -64 * 27, -64 * 9),
  new Emoji("[em-cry]", -64 * 27, -64 * 8),
  new Emoji("[em-sob]", -64 * 27, -64 * 20),
  new Emoji("[em-joy]", -64 * 26, -64 * 17),
  new Emoji("[em-astonished]", -64 * 27, -64 * 24),
  new Emoji("[em-scream]", -64 * 27, -64 * 23),

  new Emoji("[em-tired_face]", -64 * 27, -64 * 17),
  new Emoji("[em-angry]", -64 * 27, -64 * 6),
  new Emoji("[em-rage]", -64 * 27, -64 * 7),
  new Emoji("[em-triumph]", -64 * 27, -64 * 10),
  new Emoji("[em-sleepy]", -64 * 27, -64 * 16),
  new Emoji("[em-yum]", -64 * 26, -64 * 26),
  new Emoji("[em-mask]", -64 * 27, -64 * 29),
  new Emoji("[em-sunglasses]", -64 * 26, -64 * 29),
  new Emoji("[em-dizzy_face]", -64 * 27, -64 * 27),
  new Emoji("[em-imp]", -64 * 18, -64 * 26),
  new Emoji("[em-smiling_imp]", -64 * 26, -64 * 23),
  new Emoji("[em-neutral_face]", -64 * 26, -64 * 31),
  new Emoji("[em-no_mouth]", -64 * 27, -64 * 28),
  new Emoji("[em-innocent]", -64 * 26, -64 * 22),
  new Emoji("[em-alien]", -64 * 18, -64 * 24),
];

const emojisMap = new Map<string, Emoji>();

export function getEmojiMap(): Map<string, Emoji> {
  if (emojisMap.size > 0) {
    return emojisMap;
  }

  Emojis.forEach((it) => {
    emojisMap.set(it.value, it);
  });

  return emojisMap;
}

const reg = /\[[-\w+]+\]/g;
export function findEmojis(text: string | undefined): any[] | undefined {
  if (!text) {
    return;
  }

  let map = getEmojiMap();
  let emojis: any[] = [];
  let emoji: any;
  while ((emoji = reg.exec(text)) !== null) {
    emojis.push({ emoji: emoji[0], index: reg.lastIndex });
  }
  let ret: any[] = [];
  emojis?.forEach((it) => {
    let e = map.get(it.emoji);
    if (!e) {
      return;
    }
    ret.push({ emoji: e, index: it.index });
  });
  return ret;
}
