import React, { createRef } from "react";
import * as THREE from "three";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import BaseProps from "../common/BaseProps";
import { AmbientLight, DirectionalLightHelper, Vector3 } from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { Wig } from "../data/model/Wig";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ModelLoadError from "../data/error/ModelLoadError";
import "./ThreeModel.scss";
import LinearProgress from "@mui/material/LinearProgress";
import { CheckResult } from "../data/model/CheckResult";

interface IState {
  isLoading: boolean;
  headerProgress: number;
  wigProgress: number;
  headerModel: any | undefined;
  wigModel: any | undefined;
  progress: number;
  loadError: boolean;
}

interface Props extends BaseProps {
  transformResult: CheckResult | undefined;
  wig: Wig | undefined;
}

export default class ThreeModel extends React.Component<Props, IState> {
  private canvasRef: any;

  private scene: THREE.Scene;
  private camera: THREE.PerspectiveCamera;
  private renderer: THREE.WebGLRenderer;
  private controler: OrbitControls;

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      headerModel: undefined,
      wigModel: undefined,
      headerProgress: 0,
      wigProgress: 0,
      progress: 0,
      loadError: false,
      // wigCache: new Map<string, any | undefined>(),
      // src: props.src,
      // wigSrc: props.wigSrc,
      // wigTexture: props.wigTexture
    };
    this.canvasRef = createRef();

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x333333);
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 1;
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    this.camera = new THREE.PerspectiveCamera(75, 1, 0.001, 3000);

    THREE.Cache.enabled = true;

    this.controler = new OrbitControls(this.camera, this.renderer.domElement);
    // this.controler.touches = {
    //   ONE: THREE.TOUCH.ROTATE,
    //   TWO: THREE.TOUCH.ROTATE,
    // };
    this.controler.addEventListener("change", () => {
      this.renderer.render(this.scene, this.camera); //执行渲染操作
      //   console.log("camera.position", this.camera.position);
    });
  }

  private onResise = () => {
    const canvas = this.canvasRef.current;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(canvas.clientWidth, canvas.clientHeight);

    this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
    this.camera.updateProjectionMatrix();
  };

  private onLoadProgress = (event: ProgressEvent, isWig: boolean) => {
    const p = (100 * event.loaded) / event.total;
    if (isWig) {
      this.setState({
        wigProgress: p,
        progress: (this.state.headerProgress + p) / 2,
      });
    } else {
      this.setState({
        headerProgress: p,
        progress: (this.state.wigProgress + p) / 2,
      });
    }
  };

  private initLights() {
    let light = new AmbientLight(0xcccccc);
    this.scene.add(light);

    const dirLight1 = new THREE.DirectionalLight(0xffddcc, 1);
    dirLight1.position.set(1, 0.75, 0.5);
    this.scene.add(dirLight1);

    const dirLight2 = new THREE.DirectionalLight(0xccccff, 1);
    dirLight2.position.set(-1, 0.75, -0.5);
    this.scene.add(dirLight2);

    const dirLight3 = new THREE.DirectionalLight(0xffdddd, 1);
    dirLight3.position.set(0, 0.75, 1);
    this.scene.add(dirLight3);

    const dirLight4 = new THREE.DirectionalLight(0xffdddd, 1);
    dirLight4.position.set(0, 0.75, -1);
    this.scene.add(dirLight4);

    const dirLight5 = new THREE.DirectionalLight(0xffddff, 1);
    dirLight5.position.set(-0.75, 0.75, 1.5);
    this.scene.add(dirLight5);
  }

  private loadModels() {
    this.setState({
      isLoading: true,
      progress: 0,
      headerModel: undefined,
      wigModel: undefined,
      headerProgress: 0,
      wigProgress: 0,
    });

    const gltfLoader = this.getLoader(this.props.transformResult?.modelPath);
    if (this.props.transformResult) {
      gltfLoader?.load(
        this.props.transformResult.modelPath,
        (gltf: any) => {
          if (gltfLoader instanceof GLTFLoader) {
            gltf = (gltf as GLTF).scene;
          }
          this.onModelLoaded(gltf);
        },
        (event: ProgressEvent) => {
          this.onLoadProgress(event, false);
        },
        (error: any) => {
          console.error(error);
          this.onModelLoaded(new ModelLoadError(error));
        }
      );

      this.loadWig();
    }
  }

  private loadWig() {
    if (!this.props.wig) return;

    if (this.props.wig.mtlUrl) {
      var mtlLoader = new MTLLoader();
      mtlLoader.load(this.props.wig.mtlUrl, (material) => {
        material.preload();
        this.onLoadWig(material);
      });
    } else {
      this.onLoadWig();
    }
  }

  private onLoadWig(material: any = undefined) {
    const wigloader = this.getLoader(this.props.wig?.modelUrl, material);
    if (wigloader && this.props.wig?.modelUrl) {
      wigloader.load(
        this.props.wig.modelUrl,
        (obj: any) => {
          if (wigloader instanceof GLTFLoader) {
            obj = (obj as GLTF).scene;
          }
          this.onWigLoaded(obj);
        },
        (event: ProgressEvent) => {
          this.onLoadProgress(event, true);
        },
        (error: any) => {
          console.error(error);
          this.onWigLoaded(new ModelLoadError(error));
        }
      );
    }
  }

  private getLoader(
    src: string | undefined,
    material: any | undefined = undefined
  ): any {
    if (!src) {
      return null;
    }
    let type = src
      .substring(src.lastIndexOf(".") + 1, src.length)
      ?.toLowerCase();
    switch (type) {
      case "glb":
      case "gltf": {
        const loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath("/uploads/ai");
        loader.setDRACOLoader(dracoLoader);
        return loader;
      }
      case "fbx":
        return new FBXLoader();
      case "obj":
      default:
        const loader = new OBJLoader();
        material && loader.setMaterials(material);
        return loader;
    }
  }

  private onModelLoaded(model: any) {
    if (this.state.wigModel) {
      const wigModel = this.state.wigModel;
      this.setState(
        {
          headerModel: undefined,
          isLoading: false,
          progress: 0,
          headerProgress: 0,
          wigModel: undefined,
          wigProgress: 0,
        },
        () => {
          if (
            wigModel instanceof ModelLoadError ||
            model instanceof ModelLoadError
          ) {
            this.setState({
              loadError: true,
            });
          } else {
            this.render3DModels(model, wigModel);
          }
        }
      );
    } else {
      this.setState({
        headerModel: model,
        headerProgress: 100,
        progress: this.state.wigProgress / 2 + 50,
      });
    }
  }

  private onWigLoaded(model: any) {
    if (this.state.headerModel) {
      const headerModel = this.state.headerModel;
      this.setState(
        {
          headerModel: undefined,
          isLoading: false,
          progress: 0,
          headerProgress: 0,
          wigModel: undefined,
          wigProgress: 0,
        },
        () => {
          if (
            headerModel instanceof ModelLoadError ||
            model instanceof ModelLoadError
          ) {
            this.setState({
              loadError: true,
            });
          } else {
            this.render3DModels(headerModel, model);
          }
        }
      );
    } else {
      this.setState({
        wigModel: model,
        wigProgress: 100,
        progress: this.state.headerProgress / 2 + 50,
      });
    }
  }

  private render3DModels(headerModel: any, wigModel: any) {
    const group = new THREE.Group();
    this.renderWig(group, wigModel, this.getModelSize(headerModel));
    this.renderHeader(group, headerModel);


    this.camera.lookAt(group.position);

    this.controler.target = group.position;
    this.controler.update();

    this.scene.add(group);
    this.renderer.render(this.scene, this.camera);
  }

  private renderHeader(group: THREE.Group, model: THREE.Scene) {
    this.camera.position.set(0, 0, 0.3);

    let norms = this.props.transformResult?.norms;
    if (norms && norms.length > 2) {
      if (norms[0] < -0.015) {
        model.rotateX(-0.5236);
        // center the header
        model.position.z -= 0.05;
      } else if (norms[0] > 0.015) {
        model.rotateX(0.5236);
        // center the header
        model.position.z += 0.1;
      } else {
        // center the header
        model.position.z += 0.05;
      }
    }


    group.add(model);
  }

  private renderWig(group: THREE.Group, model: any, headerSize: Vector3) {
    this.repositionWig(model);
    var wigSize = this.getModelSize(model);
    // model.translateX((headerSize.x * 0.5 - headerPosition.x) - (wigSize.x * 0.5 - model.position.x));
    let deltaY = (headerSize.y - wigSize.y) * 0.5;
    model.position.y += deltaY;
    model.position.z -= 0.065;

    let norms = this.props.transformResult?.norms;
    if (norms && norms.length > 2) {
      if (norms[0] < -0.015) {
        let deltaZ = (headerSize.z - wigSize.z) * 0.5;
        model.position.z += headerSize.z / 4 - deltaZ - 0.07;
        model.position.y -= ((2 - Math.sqrt(3)) * headerSize.y) / 4 + 0.03;
      } else if (norms[0] > 0.015) {
        model.position.z -= headerSize.y / 4 - 0.07;
        model.position.y += ((2 - Math.sqrt(3)) * headerSize.y) / 4;
      } else {
        let deltaZ = (headerSize.z - wigSize.z) * 0.5;
        model.position.z -= deltaZ;
      }
    }

    group.add(model);
  }

  private repositionWig(model: any): number {
    var mroot = model;
    var bbox = new THREE.Box3().setFromObject(mroot);
    var cent = bbox.getCenter(new THREE.Vector3());
    var size = bbox.getSize(new THREE.Vector3());

    //Rescale the object to normalized space
    // var maxAxis = Math.max(size.x, size.y, size.z);
    let scalar = 0.65;
    let scale = 1; // 0.11 / scalar;
    mroot.scale.multiplyScalar(scalar);
    mroot.scale.set(scale, scale, scale);
    bbox.setFromObject(mroot);
    bbox.getCenter(cent);
    bbox.getSize(size);
    //Reposition to 0,halfY,0
    mroot.position.copy(cent).multiplyScalar(-1);
    // mroot.position.y -= size.y// * 0.5;
    return scalar;
  }

  private getModelSize(model: any) {
    let bbox = new THREE.Box3().setFromObject(model);
    return bbox.getSize(new THREE.Vector3()); // HEREyou get the size
  }

  private renderModels() {
    this.initLights();
    this.loadModels();
  }

  componentDidMount(): void {
    const canvas = this.canvasRef.current as HTMLCanvasElement;

    // this.scene.add(new THREE.AxesHelper(400));
    this.onResise();
    canvas.onresize = this.onResise;

    this.renderModels();
    canvas.appendChild(this.renderer.domElement);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (
      prevProps.transformResult !== this.props.transformResult ||
      prevProps.wig !== this.props.wig
    ) {
      this.scene.clear();
      this.renderModels();
    }
  }

  render(): React.ReactNode {
    return (
      <div className={this.props.className} ref={this.canvasRef}>
        {this.state.isLoading && (
          <div
            className={["LoadProgressContainer", this.props.className].join(
              " "
            )}
          >
            <LinearProgress
              variant="determinate"
              value={this.state.progress}
              className="LoadProgressBar"
            />

            <Box sx={{ minWidth: 35 }}>
              <Typography
                variant="body2"
                color="white"
              >{`${this.state.progress.toFixed(0)}%`}</Typography>
            </Box>
          </div>
        )}
      </div>
    );
  }
}
