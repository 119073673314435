import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";

import React from "react";
import BaseProps from "../common/BaseProps";
import "./MainBar.scss";
import menuIcon from "../images/menu.png";
import languageIcon from "../images/language.png";
import arrowDownIcon from "../images/arrow_down.png";
import { isMobile } from "../common/utils";

import { Translation } from "react-i18next";
import i18n from "../i18n";
import { connect } from "react-redux";
import { showContactUs } from "../reducer/HomeReducer";
import { ABOUT, SERVICE, SHOP, SHOP2, SPLASH, TECH } from "../RouterPath";

interface State {
  anchorElMenu: null | HTMLElement;
  languageElMenu: null | HTMLElement;
}

class MainBar extends React.Component<BaseProps, State> {
  menus = ["aboutMenu", "techMenu", "serviceMenu", "shopMenu"];
  languages = ["English"];
  constructor(props: BaseProps) {
    super(props);
    this.state = {
      anchorElMenu: null,
      languageElMenu: null,
    };
  }

  onClickContactUs() {
    const { showContactUs } = this.props as any;
    showContactUs();
  }

  handleCloseMoreMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorElMenu: null,
    });
  };

  onClickMoreMenuItem = (key: string) => {
    this.setState({
      anchorElMenu: null,
    });

    switch (key) {
      case this.menus[0]:
        window.location.href = ABOUT;
        break;
      case this.menus[1]:
        window.location.href = TECH;
        break;
      case this.menus[2]:
        window.location.href = SERVICE;
        break;
      case this.menus[3]:
        window.location.href = "https://shop.smartwig.co.uk";
        break;
    }
  };

  handleOpenMoreMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorElMenu: event.currentTarget,
    });
  };

  handleOpenLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      languageElMenu: event.currentTarget,
    });
  };

  handleCloseLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      languageElMenu: null,
    });
    if (event == null || (event.target as any).textContent === "") {
      return;
    } else if ((event.target as any).textContent === this.languages[0]) {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("zh-CN");
    }
  };

  render(): React.ReactNode {
    return (
      <Translation>
        {(t) => (
          <AppBar position="sticky">
            <Toolbar className="mainBarContainer">
              <Typography
                variant="h1"
                noWrap
                component="a"
                href={SPLASH}
                className={isMobile() ? "logoMobile" : "logo"}
              >
                SmartWig
              </Typography>

              <img src={languageIcon} className="languageIcon" alt="language" />

              <Box sx={{ flexGrow: 0, display: { md: "flex" } }}>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  className="language"
                  onClick={this.handleOpenLanguageMenu}
                >
                  {t("language")}
                  <img
                    src={arrowDownIcon}
                    className={
                      isMobile() ? "languageArraowMobile" : "languageArraow"
                    }
                    alt="arrow"
                  />
                </IconButton>

                <Menu
                  id="language-options"
                  anchorEl={this.state.languageElMenu}
                  keepMounted
                  open={Boolean(this.state.languageElMenu)}
                  onClose={this.handleCloseLanguageMenu}
                  classes={{ paper: "menu" }}
                >
                  {this.languages.map((lan: string) => (
                    <MenuItem key={lan} onClick={this.handleCloseLanguageMenu}>
                      <Typography textAlign="center" sx={{ color: "white" }}>
                        {lan}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

              <Box sx={{ flexGrow: 0, display: { md: "flex" } }}>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={this.handleOpenMoreMenu}
                >
                  <img src={menuIcon} className="menuIcon" alt="menu" />
                </IconButton>
                <Menu
                  id="more-menu"
                  anchorEl={this.state.anchorElMenu}
                  keepMounted
                  open={Boolean(this.state.anchorElMenu)}
                  onClose={this.handleCloseMoreMenu}
                  classes={{ paper: "menu" }}
                >
                  {this.menus.map((menu: string) => (
                    <MenuItem
                      key={menu}
                      onClick={() => {
                        this.onClickMoreMenuItem(menu);
                      }}
                    >
                      <Typography textAlign="center" sx={{ color: "white" }}>
                        {t(menu)}
                      </Typography>

                      <Divider className="divider" />
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  showContactUs: () => {
    dispatch(showContactUs());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MainBar);
