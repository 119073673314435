import axios from "axios";
import { getCookieValue } from "../../common/utils";
import { API_PATH, BASE_PATH, IS_DEMO } from "../../config";
import { TRANSFORM_NOT_COMPLETE } from "../ApiErrorCode";
import ApiError from "../error/ApiError";
import HttpError from "../error/HttpError";
import TimeoutError from "../error/TimeoutError";
import { IHttpRequest } from "./IHttpRequest";
import { CheckResult } from "../model/CheckResult";

const TRANSFORM_PATH = API_PATH + "/generate";
const CHECK_RESULT_PATH = API_PATH + "/check";
const RETRY_COUNT = 200;

export default class TransformRequest implements IHttpRequest {
  imageData: string | undefined = undefined;
  timer: any = null;
  // callback: ((success: boolean, modelUrl?: string, error?: any) => void) | undefined = undefined

  url: string;
  demoResult = new CheckResult(BASE_PATH + "/temp/fb6389f0ffcd11ed9f3c6657ec214ba2/result/fb6389f0ffcd11ed9f3c6657ec214ba2.obj");

  csrftoken = getCookieValue("csrftoken");

  constructor(imgData: string) {
    this.imageData = imgData;
    this.url = TRANSFORM_PATH;
  }

  async sendRequest(
    callback: (success: boolean, checkResult?: CheckResult, error?: any) => void
  ) {
    try {
      let response = await axios.post(this.url, this.imageData, {
        headers: {
          "X-CSRFToken": this.csrftoken,
        },
      });
      let data = response.data;

      if (response.status !== axios.HttpStatusCode.Ok) {
        this.onFailedSendTransform(
          new HttpError(axios.HttpStatusCode.Ok),
          callback
        );
      } else {
        if (data.code === 0) {
          await this.checkResult(
            data.data.taskId,
            IS_DEMO ? 10 : RETRY_COUNT,
            callback
          );
        } else {
          this.onFailedSendTransform(
            new HttpError(axios.HttpStatusCode.Ok),
            callback
          );
        }
      }
    } catch (error) {
      this.onFailedSendTransform(error, callback);
    }
  }

  onFailedSendTransform(
    e: any,
    callback: (success: boolean, modelUrl?: any, error?: any) => void
  ) {
    if (IS_DEMO) {
      this.checkResult("demo", 0, callback);
    } else {
      callback && callback(false, undefined, e);
    }
  }

  cancel() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  private async checkResult(
    taskId: string,
    retryCnt: number,
    callback: (success: boolean, modelUrl?: any, error?: any) => void
  ) {
    if (retryCnt <= 0) {
      if (IS_DEMO) {
        callback && callback(true, this.demoResult);
      } else {
        callback && callback(false, undefined, new TimeoutError());
      }

      return;
    }

    this.timer = setTimeout(async () => {
      await this.doCheckResult(taskId, retryCnt, callback);
    }, 2000);
  }

  async doCheckResult(
    taskId: string,
    retryCnt: number,
    callback: (success: boolean, modelUrl?: any, error?: any) => void
  ) {
    try {
      let response = await axios.get(CHECK_RESULT_PATH, {
        params: {
          taskId: taskId,
        },
        responseType: "json",
      });

      let data = response.data;

      if (response.status !== axios.HttpStatusCode.Ok) {
        this.checkResult(taskId, --retryCnt, callback);
        console.log(`Error http code: ${response.status}`);
        // callback && callback(false, undefined, new HttpError(axios.HttpStatusCode.Ok))
      } else {
        if (data.code === 0) {
          callback && callback(true, this.parseResult(data.data));
        } else if (data.code === TRANSFORM_NOT_COMPLETE) {
          this.checkResult(taskId, --retryCnt, callback);
        } else {
          if (IS_DEMO) {
            callback && callback(true, this.demoResult);
          } else {
            callback &&
              callback(false, undefined, new ApiError(data.code, data.message));
          }
        }
      }
    } catch (e) {
      console.log("Error:" + e);
      this.checkResult(taskId, --retryCnt, callback);
    }
  }

  parseResult(data: any): CheckResult {
    let result = {...data}
    return result
  }
}
