import { CircularProgress } from "@mui/material";
import React from "react";
import { Translation } from "react-i18next";
import BaseProps from "../common/BaseProps";
import TransformRequest from "../data/request/TransformRequest";
import "./Transforming.scss";
import { CheckResult } from "../data/model/CheckResult";

interface Props extends BaseProps {
  imageData: string;
  onFinishTransform?: (success: boolean, result?: CheckResult) => void;
}
export default class Transforming extends React.Component<Props> {
  request: TransformRequest | undefined = undefined;
  componentDidMount(): void {
    setTimeout(() => {
      this.request = new TransformRequest(this.props.imageData);
      this.request.sendRequest(this.requestCallback);
    }, 500);
  }

  requestCallback = (success: boolean, result?: CheckResult, error?: any) => {
    if (success) {
      this.props.onFinishTransform &&
        this.props.onFinishTransform(true, result);
    } else {
      console.log(`modelUrl:${result}\nError:${error}`);
      this.props.onFinishTransform && this.props.onFinishTransform(false);
    }
  };

  componentWillUnmount(): void {
    this.request?.cancel();
  }

  render(): React.ReactNode {
    return (
      <Translation>
        {(t) => (
          <div className="TransformingContent">
            <CircularProgress className="TransformingProgress"></CircularProgress>
            <div className="TransformingTips">{t("transfoming")}</div>
          </div>
        )}
      </Translation>
    );
  }
}
