import React from "react";
import "../common/Common.scss";
import ThreeDViewer from "../components/ThreeDViewer";
import { Wig } from "../data/model/Wig";
import ThreeModel from "../components/ThreeModel";
import { CheckResult } from "../data/model/CheckResult";

export default class Test extends React.Component {
  render(): React.ReactNode {
    const wigs = new Wig();
    wigs.modelUrl = "temp/hair1.glb";
    return (
      <div className="Page">
        <ThreeModel
          transformResult={
            new CheckResult("temp/result/e3e33ad0ef0811eda1606657ec214ba3.obj")
          }
          wig={wigs}
          className="Page"
        />

        {/* <ThreeDViewer src="/static/temp/hair1.glb" className="Page" /> */}
      </div>
    );
  }
}
