/*获取指定名称的cookie值*/
export function getCookieValue(name: string) {
    let result = document.cookie.match(
        "(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)"
    );
    return result ? result.pop() : "";
}

export function isMobile() {

    const u = navigator.userAgent;

    if (u.indexOf('Android') > -1 || u.indexOf('iPhone') > -1) {

        return true;

    }

    return false;
}