import React from "react";
import BaseProps from "../common/BaseProps";
import "./HairStyleItem.scss";
import forbidden from "../images/forbidden.png";
import { Wig } from "../data/model/Wig";
interface Proper extends BaseProps {
  wig: Wig | undefined;
  isSelected?: boolean;
  onSelect?: (style: any) => void;
}

interface State {}

export default class HairStyleItem extends React.Component<Proper, State> {

  componentDidMount(): void {
    if (this.props.isSelected) {
      this.props.onSelect && this.props.onSelect(this.props.wig);
    }
  }

  render(): React.ReactNode {
    return (
      <div
        className="itemContainer"
        onClick={() => {
          this.props.onSelect && this.props.onSelect(this.props.wig);
        }}
      >
        {this.props.wig?.cover ? (
          <div
            className={
              this.props.isSelected ? "ImageContanerSelected" : "ImageContaner"
            }
          >
            <img src={this.props.wig?.cover} className="ItemImage" alt="item"></img>
          </div>
        ) : (
          <img src={forbidden} className="ItemImage" alt="forbidden" />
        )}
        <div className={this.props.isSelected ? "ItemTextSelected" : "ItemText"}>{this.props.wig?.name}</div>
      </div>
    );
  }
}
