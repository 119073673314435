export class Wig {
  cover?: string | undefined;
  name?: string | null;
  type = 0;
  pk = 0;
  modelUrl: string | undefined;
  textureUrl: string | undefined;
  mtlUrl: string | undefined;
  constructor(
    imageSrc: string | undefined = undefined,
    modelUrl: string | undefined = undefined,
    name: string | null = null,
    type = 0,
    textureUrl: string | undefined = undefined,
    mtlUrl: string | undefined = undefined
  ) {
    this.cover = imageSrc;
    this.name = name;
    this.type = type;
    this.modelUrl = modelUrl;
    this.textureUrl = textureUrl;
    this.mtlUrl = mtlUrl;
  }
}

export const EmptyWig = new Wig(undefined);

export function generateDemoWigs(): Wig[] {
  let styles: Wig[] = [];
  styles.push(EmptyWig);
  styles.push(
    new Wig(
      "/static/media/avatar.75f65ff728a296ed4bfe.png",
      "/temp/hair3.glb",
      "Short hair on the side",
      1
    )
  );
  styles.push(
    new Wig(
      "/static/media/avatar.75f65ff728a296ed4bfe.png",
      "/temp/hair3.glb",
      "Short hair on the side",
      2
    )
  );
  styles.push(
    new Wig(
      "/static/media/avatar.75f65ff728a296ed4bfe.png",
      "/temp/hair3.glb",
      "Short hair on the side",
      3
    )
  );
  styles.push(
    new Wig(
      "/static/media/avatar.75f65ff728a296ed4bfe.png",
      "/temp/hair3.glb",
      "侧分超短发",
      4
    )
  );
  styles.push(
    new Wig(
      "/static/media/avatar.75f65ff728a296ed4bfe.png",
      "/temp/hair3.glb",
      "Short hair on the side",
      5
    )
  );
  styles.push(
    new Wig(
      "/static/media/avatar.75f65ff728a296ed4bfe.png",
      "/temp/hair3.glb",
      "Short hair on the side",
      6
    )
  );
  styles.push(
    new Wig(
      "/static/media/avatar.75f65ff728a296ed4bfe.png",
      "/temp/hair3.glb",
      "Short hair on the side",
      7
    )
  );

  return styles;
}
