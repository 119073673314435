import { Box, Snackbar } from "@mui/material";
import React from "react";
import { Translation } from "react-i18next";
import uuid from "react-uuid";
import BottomBar from "../components/BottomBar";
import ContactUs from "../components/ContactUs";
import MainBar from "../components/MainBar";
import BaseProps from "./BaseProps";
import { isMobile } from "./utils";

export enum PageStyle {
    white, black
}

export interface BasePageState extends BaseProps {
    showSnackBar: boolean,
    snackBarMsg: string | null
}
export default class BasePage<P = {}, S extends BasePageState = BasePageState> extends React.Component<P, S>{

    showSnakebar(msg: string) {
        if (!msg) {
            return
        }
        this.setState({
            ...this.state,
            showSnackBar: true,
            snackBarMsg: msg
        })
    }

    private onCloseSnackbar = () => {
        this.setState({
            ...this.state,
            showSnackBar: false,
            snackBarMsg: null
        })
    }
    render(): React.ReactNode {

        const { showContactUs } = this.props as any
        return <Translation>{t =>
            <div className="Page">
                <MainBar />
                <Box className={[(isMobile() ? "PageContainerMobile" : "PageContainer"), (
                    this.getStyle() === PageStyle.white ? "WhitePage" : "BlackPage"
                )].join(" ")}>
                    {showContactUs && <ContactUs />}
                    {this.renderContent(t)}
                </Box>
                {this.showBottom() && <BottomBar />}
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state?.showSnackBar}
                    onClose={this.onCloseSnackbar}
                    autoHideDuration={3000}
                    message={this.state?.snackBarMsg && t(this.state.snackBarMsg as string)}
                    key={uuid()}
                />
            </div>
        }</Translation>
    }

    getStyle(): PageStyle {
        return PageStyle.white
    }

    renderContent(t: any): React.ReactNode {
        return <div ></div>
    }

    showBottom() {
        return true
    }

}


export const mapStateToProps = (state: any) => {
    return {
        showContactUs: state.home.isShowContactUs,
    }
}

export const mapDispatchToProps = (dispatch: any) => {
    return {

    }
}